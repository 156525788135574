import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="ios" function="init" repeat="6" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public init(path: String)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`path`}</em>{` is a string representation for the surface path.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// Creates a surface instance representing a banner within homeView view in my mobile application.
let surface = Surface(path: "homeView#banner")
`}</code></pre>
    <Variant platform="android" function="constructor-parameterized" repeat="9" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Surface(final String path)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`path`}</em>{` is a string containing the surface path.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`// Creates a surface instance representing a banner view within homeActivity in my mobile application.
val surface = Surface("homeActivity#banner")
`}</code></pre>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Creates a surface instance representing a banner view within homeActivity in my mobile application.
final Surface surface = new Surface("homeActivity#banner")
`}</code></pre>
    <Variant platform="android" function="constructor-default" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Surface()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`// Creates a new base surface by appending application package name to the mobile app surface prefix mobileapp://
val surface = Surface()
`}</code></pre>
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Creates a new base surface by appending application package name to the mobile app surface prefix mobileapp://
final Surface surface = new Surface()
`}</code></pre>
    <Variant platform="android" function="get-uri" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public String getUri()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin-2"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val surface = Surface("homeActivity#banner")
val uri = surface.uri
`}</code></pre>
    <h4 {...{
      "id": "java-5"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final Surface surface = new Surface("homeActivity#banner")
final String uri = surface.getUri()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      