import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant api="sendevent" task="extension-validation" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "requried-extensions"
    }}>{`Requried Extensions`}</h4>
    <p>{`Core Extension, Edge Extension, Edge Identity Extension`}</p>
    <h4 {...{
      "id": "optional-extensions"
    }}>{`Optional Extensions`}</h4>
    <p>{`Consent Extension, Lifecycle Extension`}</p>
    <Variant api="trackapi" task="extension-validation" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "requried-extensions-1"
    }}>{`Requried Extensions`}</h4>
    <p>{`Core Extension, Edge Extension, Edge Identity Extension, Edge Bridge Extension`}</p>
    <h4 {...{
      "id": "optional-extensions-1"
    }}>{`Optional Extensions`}</h4>
    <p>{`Consent Extension, Lifecycle Extension`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      