import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Alerts from '/src/pages/resources/alerts.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Variant platform="android" task="download" repeat="8" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`Add the Mobile Core and Edge extensions to your project using the app's Gradle file.`}</li>
    </ol>
    <p><strong parentName="p">{`Kotlin`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`implementation(platform("com.adobe.marketing.mobile:sdk-bom:3.+"))
implementation("com.adobe.marketing.mobile:core")
implementation("com.adobe.marketing.mobile:identity")
implementation("com.adobe.marketing.mobile:edge")
implementation("com.adobe.marketing.mobile:edgeidentity")
`}</code></pre>
    <p><strong parentName="p">{`Groovy`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`implementation platform('com.adobe.marketing.mobile:sdk-bom:3.+')
implementation 'com.adobe.marketing.mobile:core'
implementation 'com.adobe.marketing.mobile:identity'
implementation 'com.adobe.marketing.mobile:edge'
implementation 'com.adobe.marketing.mobile:edgeidentity'
`}</code></pre>
    <Alerts query="platform=android-gradle&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Import the Mobile Core and Edge extensions in your application class but do not include the Identity or Identity for Edge Network extensions. Instead, use their fully qualified names during registration and when calling their APIs.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Edge;
`}</code></pre>
    <Variant platform="ios" task="download" repeat="7" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`Add the Mobile Core and Edge extensions to your project using CocoaPods. Add following pods in your `}<inlineCode parentName="li">{`Podfile`}</inlineCode>{`:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`use_frameworks!
target 'YourTargetApp' do
    pod 'AEPCore'
    pod 'AEPIdentity'
    pod 'AEPEdge'
    pod 'AEPEdgeIdentity'
end
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Import the Mobile Core and Edge libraries:`}</li>
    </ol>
    <p><strong parentName="p">{`Swift`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AppDelegate.swift
import AEPCore
import AEPIdentity
import AEPEdge
import AEPEdgeIdentity
`}</code></pre>
    <p><strong parentName="p">{`Objective-C`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.h
@import AEPCore;
@import AEPIdentity;
@import AEPEdge;
@import AEPEdgeIdentity;
`}</code></pre>
    <Variant platform="android" task="register" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MobileApp extends Application {
    // Set up the preferred Environment File ID from your mobile property configured in Data Collection UI
    private final String ENVIRONMENT_FILE_ID = "";

    @Override
    public void onCreate() {
      super.onCreate();
      MobileCore.setApplication(this);
      MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID);

      // Register Adobe Experience Platform SDK extensions
      MobileCore.registerExtensions(
         Arrays.asList(
            Edge.EXTENSION, 
            com.adobe.marketing.mobile.edge.identity.Identity.EXTENSION,
            com.adobe.marketing.mobile.Identity.EXTENSION
            ),
         o -> Log.debug("MobileApp", "MobileApp", "Adobe Experience Platform Mobile SDK initialized.")
       );
    }
}
`}</code></pre>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`class MobileApp : Application() {
    // Set up the preferred Environment File ID from your mobile property configured in Data Collection UI
    private var ENVIRONMENT_FILE_ID: String = ""
    override fun onCreate() {
        super.onCreate()
        MobileCore.setApplication(this)
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID)
        // Register Adobe Experience Platform SDK extensions
        MobileCore.registerExtensions(
            listOf(
                Edge.EXTENSION, 
                com.adobe.marketing.mobile.edge.identity.Identity.EXTENSION,
                com.adobe.marketing.mobile.Identity.EXTENSION
                )
        ) {
            Log.debug("MobileApp", "MobileApp", "Adobe Experience Platform Mobile SDK initialized.")
        }
    }
}
`}</code></pre>
    <Variant platform="ios" task="register" repeat="4" mdxType="Variant" />
    <p><strong parentName="p">{`Swift`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AppDelegate.swift
func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
    MobileCore.registerExtensions([AEPEdgeIdentity.Identity.self, AEPIdentity.Identity.self, Edge.self], {
    MobileCore.configureWith(appId: "yourAppId")
  })
  ...
}
`}</code></pre>
    <p><strong parentName="p">{`Objective-C`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.m
- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    [AEPMobileCore registerExtensions:@[AEPMobileEdgeIdentity.class, AEPMobileIdentity.class, AEPMobileEdge.class] completion:^{
    ...
  }];
  [AEPMobileCore configureWithAppId: @"yourAppId"];
  ...
}
`}</code></pre>
    <Variant platform="android" task="link" repeat="2" mdxType="Variant" />
    <p><strong parentName="p">{`Java`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.setPrivacyStatus(MobilePrivacyStatus.OPT_OUT);
MobileCore.resetIdentities();
com.adobe.marketing.mobile.edge.identity.Identity.getExperienceCloudId(new AdobeCallback<String>() {
    @Override
    public void call(String s) {
        // ignore
    }
});
MobileCore.setPrivacyStatus(MobilePrivacyStatus.OPT_IN);
`}</code></pre>
    <Variant platform="ios" task="link" repeat="4" mdxType="Variant" />
    <p><strong parentName="p">{`Swift`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.setPrivacyStatus(.optedOut)
MobileCore.resetIdentities()
AEPEdgeIdentity.Identity.getExperienceCloudId { _, _ in }
MobileCore.setPrivacyStatus(.optedIn)
`}</code></pre>
    <p><strong parentName="p">{`Objective-C`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore setPrivacyStatus:AEPPrivacyStatusOptedOut];
[AEPMobileCore resetIdentities];
[AEPMobileEdgeIdentity getExperienceCloudId:^(NSString *ecid, NSError *error) {
    // ignore
}];
[AEPMobileCore setPrivacyStatus:AEPPrivacyStatusOptedIn];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      