import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="obtain" repeat="3" mdxType="Variant" />
    <p>{`On Android, the web view is represented as `}<inlineCode parentName="p">{`WebView`}</inlineCode>{`.  `}</p>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
  // access to the whole message from the parent
  Message message = (Message) fullscreenMessage.getParent();
      
  WebView webView = message.getWebView();
  
  ...
}
`}</code></pre>
    <Variant platform="ios" task="obtain" repeat="3" mdxType="Variant" />
    <p>{`On iOS, the web view is represented as `}<inlineCode parentName="p">{`WKWebView`}</inlineCode>{`.  `}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func shouldShowMessage(message: Showable) -> Bool {
    // access to the whole message from the parent
    let fullscreenMessage = message as? FullscreenMessage
    let message = fullscreenMessage?.parent

    let messageWebView = message?.view as? WKWebView

    ...
}
`}</code></pre>
    <Variant platform="android" task="call" repeat="4" mdxType="Variant" />
    <p>{`With a reference to the `}<inlineCode parentName="p">{`WebView`}</inlineCode>{`, the instance method `}<inlineCode parentName="p">{`public void evaluateJavascript(@NonNull String script, @Nullable ValueCallback<String> resultCallback)`}</inlineCode>{` can now be leveraged to call a JavaScript method.`}</p>
    <p>{`Further details of this API are explained in the `}<a parentName="p" {...{
        "href": "https://developer.android.com/reference/android/webkit/WebView#evaluateJavascript(java.lang.String,%20android.webkit.ValueCallback%3Cjava.lang.String%3E)"
      }}>{`Android documentation`}</a>{` - the example below is provided for the purpose of demonstration:`}</p>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
  // access to the whole message from the parent
  Message message = (Message) fullscreenMessage.getParent();
      
  WebView webView = message.getWebView();
  // webview operations must be run on the ui thread
  webView.post(new Runnable() {
    @Override
    public void run() {
      webView.evaluateJavascript("startTimer()", new ValueCallback<String>() {
        @Override
        public void onReceiveValue(String s) {
          // do something with the content
        }
      });
    }
  });
  
  ...
}
`}</code></pre>
    <Variant platform="ios" task="call" repeat="4" mdxType="Variant" />
    <p>{`With a reference to the `}<inlineCode parentName="p">{`WKWebView`}</inlineCode>{`, the instance method `}<inlineCode parentName="p">{`evaluateJavaScript(_:completionHandler:)`}</inlineCode>{` can now be leveraged to call a JavaScript method.`}</p>
    <p>{`Further details of this API are explained in the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/webkit/wkwebview/1415017-evaluateJavaScript"
      }}>{`Apple documentation`}</a>{` - the example below is provided for the purpose of demonstration:`}</p>
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func shouldShowMessage(message: Showable) -> Bool {
    // access to the whole message from the parent
    let fullscreenMessage = message as? FullscreenMessage
    let message = fullscreenMessage?.parent

    // the \`shouldShowMessage\` delegate method is called on a background thread.
    // need to dispatch code that uses the webview back to the main thread.
    DispatchQueue.main.async {
        let messageWebView = message?.view as? WKWebView

        messageWebView?.evaluateJavaScript("startTimer();") { result, error in
            if error != nil {
                // handle error
                return
            }

            if result != nil {
                // do something with the result
            }
        }                
    }

    ...
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      