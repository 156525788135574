import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="collect-consent-settings" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p>{`You can set the collect consent to one of the following values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode></li>
    </ul>
    <p>{`To understand the expected behavior, see the `}<em parentName="p">{`Update and get collect consent preferences`}</em>{` table above.`}</p>
    <Variant platform="ios" task="collect-consent-settings" repeat="7" mdxType="Variant" />
    <p>{`You can set the collect consent to one of the following values:`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p>{`You can set the collect consent to one of the following values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode></li>
    </ul>
    <p>{`To understand the expected behavior, see the `}<em parentName="p">{`Update and get collect consent preferences`}</em>{` table above.`}</p>
    <Variant platform="android" task="get-consents" repeat="6" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void getConsents(final AdobeCallback<Map<String, Object>> callback);
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`callback`}</em>{` - callback invoked with the current consents of the extension. If an `}<inlineCode parentName="li">{`AdobeCallbackWithError`}</inlineCode>{` is provided, an `}<inlineCode parentName="li">{`AdobeError`}</inlineCode>{`, can be returned in the eventuality of any error that occured while getting the user consents. The callback may be invoked on a different thread.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Consent.getConsents(new AdobeCallback<Map<String, Object>>() {
    @Override
    public void call(Map<String, Object> currentConsents) {
        if (currentConsents == null) { return; }
    final Map<String, Object> consents = currentConsets.get("consents");
    final Map<String, Object> collectConsent = consents.get("collect");
    final String collectConsentStatus = collectConsent.get("val");
    // inspect collectConsentStatus
    }
});
`}</code></pre>
    <Variant platform="ios" task="get-consents" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func getConsents(completion: @escaping ([String: Any]?, Error?) -> Void)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Consent.getConsents { currentConsents, error in
    guard error == nil else { return }
    guard let consents = currentConsents["consents"] as? [String: Any] else { return }
    guard let collectConsent = consents["collect"] as? [String: Any] else { return }
    let collectConsentStatus = collectConsent["val"] as? String
    // inspect collectConsentStatus
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objective-c"
      }}>{`static func getConsents(completion: @escaping ([String: Any]?, Error?) -> Void)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objective-c"
      }}>{`[AEPMobileEdgeConsent getConsents:^(NSDictionary *currentConsents, NSError *error) {
    if (error) { return; }
    NSDictionary *consents = currentConsents[@"consents"];
    NSDictionary *collectConsent = currentConsents[@"collect"];
    NSString *collectConsentStatus = collectConsent[@"val"];
    // inspect collectConsentStatus
}];
`}</code></pre>
    <Variant platform="android" task="update-consents" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void update(final Map<String, Object> consents);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// example 1, updating users collect consent to 'yes'
final Map<String, Object> collectConsents = new HashMap<>();
collectConsents.put("collect", new HashMap<String, String>() {
    {
        put("val", "y");
    }
});

final Map<String, Object> consents = new HashMap<>();
consents.put("consents", collectConsents);

Consent.update(consents);

// example 2, updating users collect consent to 'no'
final Map<String, Object> collectConsents = new HashMap<>();
collectConsents.put("collect", new HashMap<String, String>() {
    {
        put("val", "n");
    }
});

final Map<String, Object> consents = new HashMap<>();
consents.put("consents", collectConsents);

Consent.update(consents);
`}</code></pre>
    <Variant platform="ios" task="update-consents" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func update(with consents: [String: Any])
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// example 1, updating users collect consent to 'yes'
let collectConsent = ["collect": ["val": "y"]]
let currentConsents = ["consents": collectConsent]
Consent.update(with: currentConsents)

// example 2, updating users collect consent to 'no'
let collectConsent = ["collect": ["val": "n"]]
let currentConsents = ["consents": collectConsent]
Consent.update(with: currentConsents)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-2"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func update(with consents: [String: Any])
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// example 1, updating users collect consent to 'yes'
NSDictionary *collectConsent = @{ @"collect": @{@"val": @"y"};
[AEPMobileEdgeConsent updateWithConsents:@{@"consents": collectConsent}];

// example 2, updating users collect consent to 'no'
NSDictionary *collectConsent = @{ @"collect": @{@"val": @"n"};
[AEPMobileEdgeConsent updateWithConsents:@{@"consents": collectConsent}];
`}</code></pre>
    <Variant platform="android" task="set-privacy-status" repeat="8" mdxType="Variant" />
    <p>{`You can set the privacy status to one of the following values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.OPT_IN`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.OPT_OUT`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.UNKNOWN`}</inlineCode></li>
    </ul>
    <p>{`To understand the expected behavior, see the "Set and get privacy status" table above.`}</p>
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void setPrivacyStatus(final MobilePrivacyStatus privacyStatus);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.setPrivacyStatus(MobilePrivacyStatus.OPT_OUT);
`}</code></pre>
    <Variant platform="ios" task="set-privacy-status" repeat="13" mdxType="Variant" />
    <p>{`You can set privacy status to one of the following values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.optedIn`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.optedOut`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.unknown`}</inlineCode></li>
    </ul>
    <p>{`To understand the expected behavior, see the `}<em parentName="p">{`Set and get privacy status`}</em>{` table above.`}</p>
    <h4 {...{
      "id": "swift-3"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func setPrivacyStatus(_ status: PrivacyStatus)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.setPrivacyStatus(.optedIn)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-3"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objective-c"
      }}>{`@objc(setPrivacyStatus:)
static func setPrivacyStatus(_ status: PrivacyStatus)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objective-c"
      }}>{`[AEPMobileCore setPrivacyStatus:AEPPrivacyStatusOptedIn];
`}</code></pre>
    <Variant platform="android" task="get-privacy-status" repeat="8" mdxType="Variant" />
    <p>{`The enum representation of the privacy status that corresponds to the following statuses:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.OPT_IN`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.OPT_OUT`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`MobilePrivacyStatus.UNKNOWN`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void getPrivacyStatus(AdobeCallback<MobilePrivacyStatus> callback);
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`callback`}</em>{` is invoked after the privacy status is available.`}</li>
      <li parentName="ul">{`If an instance of  `}<inlineCode parentName="li">{`AdobeCallbackWithError`}</inlineCode>{` is provided, and you are fetching the attributes from the Mobile SDK, the timeout value is 5000ms. If the operation times out or an unexpected error occurs, the `}<inlineCode parentName="li">{`fail`}</inlineCode>{` method is called with the appropriate `}<inlineCode parentName="li">{`AdobeError`}</inlineCode>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.getPrivacyStatus(new AdobeCallback<MobilePrivacyStatus>() {
    @Override
    public void call(MobilePrivacyStatus value) {
          System.out.println("getPrivacyStatus: " + status);
    }
});
`}</code></pre>
    <Variant platform="ios" task="get-privacy-status" repeat="14" mdxType="Variant" />
    <p>{`The enum representation of the privacy status that corresponds to the following statuses:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.optedIn`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.optedOut`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`PrivacyStatus.unknown`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "swift-4"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func getPrivacyStatus(completion: @escaping (PrivacyStatus) -> Void)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`completion`}</em>{` is invoked with the current `}<inlineCode parentName="li">{`PrivacyStatus`}</inlineCode>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.getPrivacyStatus { privacyStatus in
    switch privacyStatus {
    case .optedIn:
        print("Privacy Status: Opted in")
    case .optedOut:
        print("Privacy Status: Opted out")
    case .unknown:
        print("Privacy Status: Unknown")
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c-4"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objective-c"
      }}>{`@objc(getPrivacyStatus:)
static func getPrivacyStatus(completion: @escaping (PrivacyStatus) -> Void)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`completion`}</em>{` is invoked with the current `}<inlineCode parentName="li">{`PrivacyStatus`}</inlineCode>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore getPrivacyStatus:^(AEPPrivacyStatus status) {
    switch (status) {
        case AEPPrivacyStatusOptedIn:
            NSLog(@"Privacy status: Opted in");
            break;
        case AEPPrivacyStatusOptedOut:
            NSLog(@"Privacy status: Opted out");
            break;
        case AEPPrivacyStatusUnknown:
            NSLog(@"Privacy status: Unknown");
            break;
  }
}];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      