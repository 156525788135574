import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="campaign" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "set-up-a-server-key"
    }}>{`Set up a server key`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`To configure the app in Google Firebase, see `}<a parentName="p" {...{
            "href": "https://console.firebase.google.com/"
          }}>{`Firebase Console`}</a>{` and sign in using your Google Developer credentials.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a project and add your app under that project using the application ID that you used in the app.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://firebase.google.com/docs/android/setup#console"
          }}>{`Add Firebase to your Android project`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the generated `}<strong parentName="p">{`"google-service.json"`}</strong>{` to the project and set up the project and module level gradle files.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://firebase.google.com/docs/android/setup#console"
          }}>{`Add Firebase to your Android project`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify that Firebase server key is configured in the Campaign instance by clicking `}<strong parentName="p">{`Adobe Campaign `}{`>`}{` Administrator `}{`>`}{` Channels `}{`>`}{` Mobile App (AEP SDK)`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the mobile app from the list and verify that the push channel settings status for Android is `}<em parentName="p">{`Android key set up success`}</em>{`.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.5625%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/5530d/android-server-key.webp 320w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/0c8fb/android-server-key.webp 640w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/94b1e/android-server-key.webp 1280w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/0b34d/android-server-key.webp 1920w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/d5269/android-server-key.webp 2560w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/c5353/android-server-key.webp 2920w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/dd4a7/android-server-key.png 320w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/0f09e/android-server-key.png 640w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/bbbf7/android-server-key.png 1280w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/ac7a9/android-server-key.png 1920w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/c7a69/android-server-key.png 2560w", "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/02708/android-server-key.png 2920w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/1bae73962e69f69df7e32923d4eec0dc/bbbf7/android-server-key.png",
                "alt": "Server key configuration in Android",
                "title": "Server key configuration in Android",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
    </ol>
    <Variant platform="ios" task="campaign" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "set-up-the-apns-server-certificate"
    }}>{`Set up the APNS server certificate`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add Push Notification entitlement to your app.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://developer.apple.com/documentation/usernotifications/registering_your_app_with_apns?language=objc"
          }}>{`Enable the Push Notifications Capability`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To configure the APNS certificate, register the app on your Apple developer account and generate the certificate.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://developer.apple.com/documentation/usernotifications/setting_up_a_remote_notification_server/establishing_a_certificate-based_connection_to_apns?language=objc"
          }}>{`Establishing a Certificate-Based Connection to APNs`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify that the APNS server certificate is configured in the Campaign instance by clicking `}<strong parentName="p">{`Adobe Campaign `}{`>`}{` Administrator `}{`>`}{` Channels `}{`>`}{` Mobile App (AEP SDK)`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the mobile app from the list and verify that push channel settings status for iOS is `}<em parentName="p">{`iOS certificate with filename *.pem is successfully uploaded`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify that you are using production certificate for production environment and development certificate for development environment. `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.68750000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/5530d/campaign-ios-cert-configured.webp 320w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/0c8fb/campaign-ios-cert-configured.webp 640w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/94b1e/campaign-ios-cert-configured.webp 1280w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/0b34d/campaign-ios-cert-configured.webp 1920w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/d5269/campaign-ios-cert-configured.webp 2560w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/9b0e9/campaign-ios-cert-configured.webp 2896w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/dd4a7/campaign-ios-cert-configured.png 320w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/0f09e/campaign-ios-cert-configured.png 640w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/bbbf7/campaign-ios-cert-configured.png 1280w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/ac7a9/campaign-ios-cert-configured.png 1920w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/c7a69/campaign-ios-cert-configured.png 2560w", "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/7e21f/campaign-ios-cert-configured.png 2896w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/8d1c2c6ecbae01d74066992b367355a8/bbbf7/campaign-ios-cert-configured.png",
                "alt": "Configured push certificate in Campaign",
                "title": "Configured push certificate in Campaign",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
    </ol>
    <Variant platform="android" task="receive" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "set-up-the-android-app"
    }}>{`Set up the Android app`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create the Firebase Messaging Service and add it to the Android Manifest file.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://firebase.google.com/docs/cloud-messaging/android/client"
          }}>{`Set up a Firebase Cloud Messaging client app on Android`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate a push token for the app using FireBase Instance ID class.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`onComplete`}</inlineCode>{` function of `}<inlineCode parentName="p">{`OnCompleteListener`}</inlineCode>{`, set the Push identifier by calling the API `}<inlineCode parentName="p">{`MobileCore.setPushIdentifier`}</inlineCode>{`.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://developer.adobe.com/client-sdks/previous-versions/documentation/adobe-analytics-mobile-services/#set-up-push-messaging"
          }}>{`setPushIdentifier`}</a>{`.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "verify-that-the-push-token-has-successfully-synced-with-the-experience-cloud-id-service"
    }}>{`Verify that the push token has successfully synced with the Experience Cloud ID service`}</h4>
    <p>{`If SDK privacy status is `}<inlineCode parentName="p">{`optedout`}</inlineCode>{`, the push identifier will not be set.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Verify the push token sync with the Experience Cloud ID service (ECID).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To verify, launch your app connected to an Adobe Experience Platform Assurance session.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the list of events, verify that you have an event with type `}<em parentName="p">{`SetPushIdentifier`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the details panel on the right, verify the value of the push token for this device. The value in pushIdentifier is the same value that is sent to the ECID service. `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.6875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/5530d/push-token-to-identity.webp 320w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/0c8fb/push-token-to-identity.webp 640w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/94b1e/push-token-to-identity.webp 1280w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/64296/push-token-to-identity.webp 1600w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/dd4a7/push-token-to-identity.png 320w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/0f09e/push-token-to-identity.png 640w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/bbbf7/push-token-to-identity.png 1280w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/42cbc/push-token-to-identity.png 1600w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/bbbf7/push-token-to-identity.png",
                "alt": "Verify SetPushIdentifier event",
                "title": "Verify SetPushIdentifier event",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To verify that app's push token is mapped to the correct Experience Cloud ID (ECID) in the Campaign instance, click `}<strong parentName="p">{`Adobe Campaign `}{`>`}{` Administrator `}{`>`}{` Channels `}{`>`}{` Mobile App (AEP SDK)`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select your app, under the mobile application subscribers verify that the Experience Cloud ID and the Registration token for the user is displayed. `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.4375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/5530d/subscriber-list-android.webp 320w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/0c8fb/subscriber-list-android.webp 640w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/94b1e/subscriber-list-android.webp 1280w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/d769a/subscriber-list-android.webp 1866w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/dd4a7/subscriber-list-android.png 320w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/0f09e/subscriber-list-android.png 640w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/bbbf7/subscriber-list-android.png 1280w", "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/c3aa1/subscriber-list-android.png 1866w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/54cbd0ebd848f71b44dbe620eb32ee9b/bbbf7/subscriber-list-android.png",
                "alt": "App subscriber list, verify mid and push token",
                "title": "App subscriber list, verify mid and push token",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you are using Charles, verify that the push token has successfully synced with the ECID service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Check for the `}<em parentName="p">{`demdex request`}</em>{`, which is marked with the red line in the screenshot below.  `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify the successful response(200) for this network call.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.9375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/793892897ccfc306af2bc812792d96d2/5530d/push-identifier.webp 320w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/0c8fb/push-identifier.webp 640w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/94b1e/push-identifier.webp 1280w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/0b34d/push-identifier.webp 1920w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/135a0/push-identifier.webp 2321w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/793892897ccfc306af2bc812792d96d2/dd4a7/push-identifier.png 320w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/0f09e/push-identifier.png 640w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/bbbf7/push-identifier.png 1280w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/ac7a9/push-identifier.png 1920w", "/client-sdks/static/793892897ccfc306af2bc812792d96d2/436e0/push-identifier.png 2321w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/793892897ccfc306af2bc812792d96d2/bbbf7/push-identifier.png",
                "alt": "ECID network request for push token sync",
                "title": "ECID network request for push token sync",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
    </ol>
    <Variant platform="ios" task="receive" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "set-up-the-ios-app"
    }}>{`Set up the iOS app`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`After you launch the app, call `}<inlineCode parentName="p">{`registerForRemoteNotifications`}</inlineCode>{` to register the app and receive the APNS token.`}</p>
        <p parentName="li">{`This API generates and returns the APNS token through the `}<inlineCode parentName="p">{`application:didRegisterForRemoteNotificationsWithDeviceToken:`}</inlineCode>{` delegate function.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "https://developer.apple.com/documentation/usernotifications/registering_your_app_with_apns?language=objc"
          }}>{`Registering Your App with APNs`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In `}<inlineCode parentName="p">{`application:didRegisterForRemoteNotificationsWithDeviceToken:`}</inlineCode>{`, call `}<inlineCode parentName="p">{`setPushIdentifier`}</inlineCode>{` to set the Push identifier.`}</p>
        <p parentName="li">{`For more information, see `}<a parentName="p" {...{
            "href": "../../home/base/mobile-core/api-reference.md#setpushidentifier"
          }}>{`setPushIdentifier`}</a>{`.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "verify-that-the-push-token-has-successfully-synced-with-the-experience-cloud-id-service-1"
    }}>{`Verify that the push token has successfully synced with the Experience Cloud ID service`}</h4>
    <p>{`If SDK privacy status is `}<inlineCode parentName="p">{`optedout`}</inlineCode>{`, the push identifier will not be set.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Verify the push token sync with the Experience Cloud ID service (ECID).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To verify, launch your app connected to an Adobe Experience Platform Assurance session.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the list of events, verify that you have an event with type `}<em parentName="p">{`SetPushIdentifier`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the details panel on the right, verify the value of the push token for this device.`}</p>
        <p parentName="li">{`The value in pushIdentifier is the same value that is sent to the ECID service. `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.6875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/5530d/push-token-to-identity.webp 320w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/0c8fb/push-token-to-identity.webp 640w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/94b1e/push-token-to-identity.webp 1280w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/64296/push-token-to-identity.webp 1600w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/dd4a7/push-token-to-identity.png 320w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/0f09e/push-token-to-identity.png 640w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/bbbf7/push-token-to-identity.png 1280w", "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/42cbc/push-token-to-identity.png 1600w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/af9de721e60b95da6683e1ac6d5cdfde/bbbf7/push-token-to-identity.png",
                "alt": "Verify SetPushIdentifier event",
                "title": "Verify SetPushIdentifier event",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To verify that app's push token is mapped to the correct Experience Cloud ID (ECID) in the Campaign instance, click `}<strong parentName="p">{`Adobe Campaign `}{`>`}{` Administrator `}{`>`}{` Channels `}{`>`}{` Mobile App (AEP SDK)`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select your app, under the mobile application subscribers verify that the Experience Cloud ID and the Registration token for the user is displayed. `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.4375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/5530d/subscriber-list-ios.webp 320w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/0c8fb/subscriber-list-ios.webp 640w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/94b1e/subscriber-list-ios.webp 1280w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/513a3/subscriber-list-ios.webp 1862w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/dd4a7/subscriber-list-ios.png 320w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/0f09e/subscriber-list-ios.png 640w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/bbbf7/subscriber-list-ios.png 1280w", "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/e596b/subscriber-list-ios.png 1862w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/4e9d0aa2bd6ec1e4495f555010838105/bbbf7/subscriber-list-ios.png",
                "alt": "App subscriber list, verify mid and Registration token",
                "title": "App subscriber list, verify mid and Registration token",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you are using Charles, verify that the push token has successfully synced with the ECID service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Check for the `}<strong parentName="p">{`demdex request`}</strong>{`, which is marked with the red line in the screenshot below.  `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify the successful response (200) for this network call.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.9375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block",
                "transition": "opacity 0.5s 0.5s",
                "pointerEvents": "none"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/5530d/charles-demdex-call-ios.webp 320w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/0c8fb/charles-demdex-call-ios.webp 640w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/94b1e/charles-demdex-call-ios.webp 1280w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/0b34d/charles-demdex-call-ios.webp 1920w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/d5269/charles-demdex-call-ios.webp 2560w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/f14b6/charles-demdex-call-ios.webp 3302w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/dd4a7/charles-demdex-call-ios.png 320w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/0f09e/charles-demdex-call-ios.png 640w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/bbbf7/charles-demdex-call-ios.png 1280w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/ac7a9/charles-demdex-call-ios.png 1920w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/c7a69/charles-demdex-call-ios.png 2560w", "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/0aca3/charles-demdex-call-ios.png 3302w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/client-sdks/static/2e142ff6c233163c780fbbfe433de5b5/bbbf7/charles-demdex-call-ios.png",
                "alt": "ECID network request for push token sync",
                "title": "ECID network request for push token sync",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "opacity": "0",
                  "transition": "opacity 0.5s",
                  "color": "inherit",
                  "boxShadow": "inset 0px 0px 0px 400px none",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      