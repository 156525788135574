import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="unable-to-open-app" repeat="1" mdxType="Variant" />
    <p>{`Follow the `}<a parentName="p" {...{
        "href": "https://developer.android.com/training/app-links/deep-linking"
      }}>{`Android documention`}</a>{` on information about how to set up a deeplink.`}</p>
    <Variant platform="ios" task="unable-to-open-app" repeat="1" mdxType="Variant" />
    <p>{`Follow the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/inter-process_communication/allowing_apps_and_websites_to_link_to_your_content/defining_a_custom_url_scheme_for_your_app"
      }}>{`Apple developer`}</a>{` documentation to set a custom URL scheme for your application.`}</p>
    <Variant platform="android" task="register-assurance" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MobileApp extends Application {
   @Override
   public void onCreate() {
      super.onCreate();
      MobileCore.setApplication(this);
      MobileCore.configureWithAppId("yourAppId");
      try {
         Assurance.registerExtension();
         MobileCore.start(null);
      } catch (Exception e) {
         // Log the exception
      }
   }
  }
`}</code></pre>
    <Variant platform="ios" task="register-assurance" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
     ACPCore.configure(withAppId: "yourAppId")   
     AEPAssurance.registerExtension() // <-- register AEPAssurance with Core
     ACPCore.start(nil)
     // Override point for customization after application launch. 
     return true;
}
`}</code></pre>
    <Variant platform="android" task="copy-link" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`AdobeExperienceSDK: Assurance - Not a valid Assurance deeplink, Ignoring start session API call. URL :  <deeplink URL>
`}</code></pre>
    <Variant platform="ios" task="copy-link" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`AdobeExperienceSDK: Assurance - Not a valid Assurance deeplink, Ignoring start session API call. URL :  <deeplink URL>
`}</code></pre>
    <Variant platform="android" task="invalid-configuration-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`W/AdobeExperienceSDK: Assurance - Assurance connection closed. Reason: Invalid Configuration, Description: The Experience Cloud organization identifier is unavailable from the SDK. Ensure SDK configuration is setup correctly. See documentation for more detail.
`}</code></pre>
    <Variant platform="ios" task="invalid-configuration-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`[AdobeExperienceSDK ERROR <AEPAssurance>]: Invalid Configuration, Description: The Experience Cloud organization identifier is unavailable from the SDK. Ensure SDK configuration is setup correctly. See documentation for more detail.
`}</code></pre>
    <Variant platform="android" task="unauthorized-access-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`W/AdobeExperienceSDK: Assurance - Assurance connection closed. Reason: Unauthorized Access, Description: The Experience Cloud organization identifier does not match with that of the Assurance session. Ensure the right Experience Cloud organization is being used. See documentation for more detail.
`}</code></pre>
    <Variant platform="ios" task="unauthorized-access-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`[AdobeExperienceSDK ERROR <AEPAssurance>]: Assurance connection closed. Reason: Unauthorized Access, Description: The Experience Cloud organization identifier does not match with that of the Assurance session. Ensure the right Experience Cloud organization is being used. See documentation for more detail.
`}</code></pre>
    <Variant platform="android" task="timeout-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`D/AdobeExperienceSDK: Assurance - Timeout - Assurance did not receive deeplink to start Assurance session within 5 seconds. Shutting down Assurance extension
`}</code></pre>
    <Variant platform="ios" task="timeout-sample-logs" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`[AdobeExperienceSDK DEBUG <AEPAssurance>]: Timeout - Assurance extension did not receive session url. Shutting down from processing any further events.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      