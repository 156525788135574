import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Alerts from '/src/pages/resources/alerts.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Variant platform="android" task="add" repeat="6" mdxType="Variant" />
    <p>{`Add the Mobile Core, Lifecycle and Campaign Classic dependencies in your app's Gradle file.`}</p>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`implementation(platform("com.adobe.marketing.mobile:sdk-bom:3.+"))
implementation("com.adobe.marketing.mobile:core")
implementation("com.adobe.marketing.mobile:lifecycle")
implementation("com.adobe.marketing.mobile:campaignclassic")
`}</code></pre>
    <h4 {...{
      "id": "groovy"
    }}>{`Groovy`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`implementation platform('com.adobe.marketing.mobile:sdk-bom:3.+')
implementation 'com.adobe.marketing.mobile:core'
implementation 'com.adobe.marketing.mobile:lifecycle'
implementation 'com.adobe.marketing.mobile:campaignclassic'
`}</code></pre>
    <Alerts query="platform=android-gradle&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <Variant platform="ios" task="add" repeat="4" mdxType="Variant" />
    <p>{`Add the Campaign Classic and `}<a parentName="p" {...{
        "href": "../../home/base/mobile-core/index.md"
      }}>{`Mobile Core`}</a>{` libraries to your project.`}</p>
    <p>{`You can add the following pods to your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`pod 'AEPCore', '~> 5.0'
pod 'AEPCampaignClassic', '~> 5.0'
`}</code></pre>
    <p>{`or you can manually include the XCFrameworks by following this GitHub `}<a parentName="p" {...{
        "href": "https://github.com/adobe/aepsdk-campaignclassic-ios/#binaries"
      }}>{`documentation`}</a>{`.`}</p>
    <Variant platform="android" task="register" repeat="5" mdxType="Variant" />
    <p>{`In your app's `}<inlineCode parentName="p">{`OnCreate`}</inlineCode>{` method, register the Campaign Classic and Lifecycle extensions:`}</p>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.CampaignClassic;
import com.adobe.marketing.mobile.Lifecycle;

public class MainApp extends Application {

    private final String ENVIRONMENT_FILE_ID = "YOUR_APP_ENVIRONMENT_ID";

    @Override
    public void onCreate() {
        super.onCreate();
        MobileCore.setApplication(this);
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID);
        
        MobileCore.registerExtensions(
            Arrays.asList(CampaignClassic.EXTENSION, Lifecycle.EXTENSION),
            o -> Log.d("MainApp", "Adobe Experience Platform Campaign Classic Mobile SDK was initialized.")
        );
    }
}
`}</code></pre>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.CampaignClassic;
import com.adobe.marketing.mobile.Lifecycle;

class MainApp : Application() {

  private var ENVIRONMENT_FILE_ID: String = "YOUR_APP_ENVIRONMENT_ID"

    override fun onCreate() {
        super.onCreate()

        MobileCore.setApplication(this)
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID)

        MobileCore.registerExtensions(
          listOf(CampaignClassic.EXTENSION, Lifecycle.EXTENSION)
        ) {
          Log.d("MainApp", "Adobe Experience Platform Campaign Classic Mobile SDK was initialized")
        }
    }

}
`}</code></pre>
    <Variant platform="ios" task="register" repeat="5" mdxType="Variant" />
    <p>{`In your app's `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` method, register the Campaign Classic extension:`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AppDelegate.swift

import AEPCore
import AEPCampaignClassic

func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
  MobileCore.registerExtensions([CampaignClassic.self], {
    MobileCore.configureWith(appId: "<YOUR_ENVIRONMENT_FILE_ID>")
  })
  return true;
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.m

@import AEPCore;
@import AEPCampaignClassic;

- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    [AEPMobileCore registerExtensions:@[AEPMobileCampaignClassic.class] completion:^{
    [AEPMobileCore configureWithAppId: @"<YOUR_ENVIRONMENT_FILE_ID>"];
  }];
  return YES;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      