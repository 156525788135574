import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" function="register" repeat="5" mdxType="Variant" />
    <p>{`In the `}<inlineCode parentName="p">{`shouldShowMessage`}</inlineCode>{` function of the `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{`, call `}<inlineCode parentName="p">{`public void handleJavascriptMessage(final String name, final AdobeCallback<String> callback)`}</inlineCode>{` to register your handler.`}</p>
    <p>{`The name of the message you intend to pass from the JavaScript side should be specified in the first parameter.`}</p>
    <p>{`The following example shows a handler that dispatches a `}<inlineCode parentName="p">{`decisioning.propositionInteract`}</inlineCode>{` Experience Event natively when the JavaScript of the in-app message posts a `}<inlineCode parentName="p">{`myInappCallback`}</inlineCode>{` message:`}</p>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
  Message message = (Message) fullscreenMessage.getParent();
  
  // in-line handling of JavaScript calls
  message.handleJavascriptMessage("myInappCallback", new AdobeCallback<String>() {
    @Override
    public void call(String content) {
      System.out.println("JavaScript body passed to native callback: " + content);
      message.track(content, MessagingEdgeEventType.IN_APP_INTERACT);
    }
  });
}
`}</code></pre>
    <Variant platform="ios" function="register" repeat="5" mdxType="Variant" />
    <p>{`In the `}<inlineCode parentName="p">{`shouldShowMessage`}</inlineCode>{` function of the `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{`, call `}<inlineCode parentName="p">{`handleJavascriptMessage(_:withHandler)`}</inlineCode>{` to register your handler.`}</p>
    <p>{`The name of the message you intend to pass from the JavaScript side should be specified in the first parameter.`}</p>
    <p>{`The following example shows a handler that dispatches a `}<inlineCode parentName="p">{`decisioning.propositionInteract`}</inlineCode>{` Experience Event natively when the JavaScript of the in-app message posts a `}<inlineCode parentName="p">{`myInappCallback`}</inlineCode>{` message:`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func shouldShowMessage(message: Showable) -> Bool {    
    let fullscreenMessage = message as? FullscreenMessage
    let message = fullscreenMessage?.parent

    // in-line handling of JavaScript calls
    message?.handleJavascriptMessage("myInappCallback") { content

        print("JavaScript body passed to native callback: \\(content ?? "empty")")

        message?.track(content as? String, withEdgeEventType: .inappInteract)
    }

    return true
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      