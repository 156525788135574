import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// 3. Send the XDM data using the Edge extension, by specifying Product Reviews Dataset identifiers as
// shown in Adobe Experience Platform
// Note: the Dataset identifier specified at Event level overrises the Experience Event Dataset specified in the
// Datastream configuration
xdmData["eventType"] = "product.review"
let experienceEvent =
ExperienceEvent(xdm: xdmData, datasetIdentifier: "<DatasetIdentifier>")
Edge.sendEvent(experienceEvent: experienceEvent)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      