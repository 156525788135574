import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="fcm" template="multi-icon" repeat="13" mdxType="Variant" />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`The properties below define the payload sent to FCM:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payload Version`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Version of the payload assigned by the ACC authoring UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Template Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Informs the reader which properties may exist in the template object.`}<br />{`Multi-icon template uses a value of "icon".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound played when notification is delivered.`}<br />{`Value should be the name of the sound file without its extension.`}<br />{`Sound file should be located in the `}<inlineCode parentName="td">{`assets/raw`}</inlineCode>{` directory of your app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link URI`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when user clicks the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Type of link represented in `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` - one of "WEBURL", "DEEPLINK", "DISMISS", or "OPENAPP".`}<br />{`Required if `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` is specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Channel ID`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The `}<a parentName="td" {...{
              "href": "https://developer.android.com/guide/topics/ui/notifiers/notifications#ManageChannels"
            }}>{`notification's channel id`}</a>{` (new in Android O). The app must create a channel with this channel ID before any notification with this channel ID is received. If you don't send this channel ID in the request, or if the channel ID provided has not yet been created by the app, FCM uses the channel ID specified in the app manifest.`}<br />{`If not provided in payload, SDK uses a "default" channel ID of value "General Notifications".`}<br />{`If < API 26 (Android O), this value is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Badge Count`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value to be used on app's badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Priority`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Designates the notification priority for Android - `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/android/app/NotificationChannel#setImportance(int)"
            }}>{`importance`}</a>{` for API >= 26 (Android 8.0) or `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/androidx/core/app/NotificationCompat.Builder#setPriority(int)"
            }}>{`priority`}</a>{` for API < 26.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Identifier used to replace existing notifications in the notification drawer. If not specified, each request creates a new notification. If specified and a notification with the same tag is already being shown, the new notification replaces the existing one in the notification drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ticker`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_ticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the "ticker" text, which is sent to accessibility services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for notification's background.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cancel button image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_cancel_image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI or bundled image representing the close icon in the right most side.`}<br />{`In case no value is provided, a default bundled "cross" image is shown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Image buttons`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Three to five icon buttons for the notification.`}<br /><strong parentName="td">{`The value is an encoded JSON string.`}</strong><br />{` When decoded, the string contains an array of the following objects:`}<br /><ul><li><inlineCode parentName="td">{`img`}</inlineCode>{` (required) - URI or bundled image representing this rating.`}</li><li><inlineCode parentName="td">{`uri`}</inlineCode>{` (required) - URI to be handled when the user clicks this rating.`}</li><li><inlineCode parentName="td">{`type`}</inlineCode>{` (required) - Type of link represented in `}<inlineCode parentName="td">{`uri`}</inlineCode>{` - one of "WEBURL", "DEEPLINK", "DISMISS", or "OPENAPP".`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <img {...{
      "src": "/client-sdks/assets/b91ca9417da9fdeff626f0b5223099bd/multi_icon.gif",
      "width": "50%",
      "height": "50%"
    }}></img>
    <p>{`Below is a sample of what a payload might look like for a multi icon notification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message": {
    "token": "FCM_TOKEN",
    "android": {
      "collapse_key": "new message"
    },
    "data": {
      "adb_version": "1",
      "adb_template_type": "icon",
      "adb_clr_icon": "123456",
      "adb_clr_bg": "000000",
      "adb_cancel_image": "delete",
      "adb_items": "[{\\"img\\":\\"https://sneakerland.com/products/assets/shoe1.png\\",\\"uri\\":\\"myapp://chooseShoeType/shoe1\\",\\"type\\":\\"DEEPLINK\\"},{\\"img\\":\\"https://sneakerland.com/products/assets/shoe2.png\\",\\"uri\\":\\"myapp://chooseShoeType/shoe2\\",\\"type\\":\\"DEEPLINK\\"},{\\"img\\":\\"https://sneakerland.com/products/assets/shoe3.png\\",\\"uri\\":\\"myapp://chooseShoeType/shoe3\\",\\"type\\":\\"DEEPLINK\\"},{\\"img\\":\\"https://sneakerland.com/products/assets/shoe4.png\\",\\"uri\\":\\"myapp://chooseShoeType/shoe4\\",\\"type\\":\\"DEEPLINK\\"},{\\"img\\":\\"https://sneakerland.com/products/assets/shoe5.png\\",\\"uri\\":\\"myapp://chooseShoeType/shoe5\\",\\"type\\":\\"DEEPLINK\\"}]"
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "usage-recommendations"
    }}>{`Usage recommendations`}</h2>
    <p>{`The tables below contain guidelines for your push notification content. `}<strong parentName="p">{`These recommendations help your images display reliably across multiple devices.`}</strong>{` These values are guidelines only - you should still test a notification prior to sending it.`}</p>
    <h3 {...{
      "id": "audio-specifications"
    }}>{`Audio specifications`}</h3>
    <p>{`Custom sound files must be placed within the app's bundle in the `}<inlineCode parentName="p">{`res/raw`}</inlineCode>{` directory. Supported formats are `}<inlineCode parentName="p">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="p">{`wav`}</inlineCode>{`, or `}<inlineCode parentName="p">{`ogg`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "image-specifications"
    }}>{`Image specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Aspect ratios`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Image size range`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supported file types`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1:1, 3:2, 5:4, 4:3`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`< 1 MB`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`PNG, JPG, WebP`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      