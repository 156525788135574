import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" section="install-dependency" repeat="2" mdxType="Variant" />
    <p>{`Download extension with dependencies using `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{` & `}<a parentName="p" {...{
        "href": "https://gradle.org/"
      }}>{`Gradle`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- implementation 'com.adobe.marketing.mobile:core:2.+'
- implementation 'com.adobe.marketing.mobile:identity:2.+'
- implementation 'com.adobe.marketing.mobile:analytics:2.+'
- implementation 'com.adobe.marketing.mobile:media:3.+'
+ implementation platform('com.adobe.marketing.mobile:sdk-bom:2.+')
+ implementation 'com.adobe.marketing.mobile:core'
+ implementation 'com.adobe.marketing.mobile:edge'
+ implementation 'com.adobe.marketing.mobile:edgeidentity'
+ implementation 'com.adobe.marketing.mobile:edgemedia'
`}</code></pre>
    <Variant platform="ios" section="install-dependency" repeat="10" mdxType="Variant" />
    <p>{`Download extension with dependencies`}</p>
    <h4 {...{
      "id": "1-using-cocoapods"
    }}>{`1. Using Cocoapods`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`  pod 'AEPCore'
- pod 'AEPAnalytics'
- pod 'AEPMedia'
+ pod 'AEPEdge'
+ pod 'AEPEdgeIdentity'
+ pod 'AEPEdgeMedia'
`}</code></pre>
    <h4 {...{
      "id": "2-using-spm"
    }}>{`2. Using SPM`}</h4>
    <p>{`Import the package:`}</p>
    <p>{`a. Using repository URL`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- https://github.com/adobe/aepsdk-media-ios.git
+ https://github.com/adobe/aepsdk-edgemedia-ios.git
`}</code></pre>
    <p>{`b. Using `}<inlineCode parentName="p">{`Package.swift`}</inlineCode>{` file`}</p>
    <p>{`Make changes to your dependencies as shown below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`  dependencies: [
  .package(url: "https://github.com/adobe/aepsdk-core-ios.git", .upToNextMajor(from: "4.0.0")),
- .package(url: "https://github.com/adobe/aepsdk-analytics-ios.git", .upToNextMajor(from: "4.0.0")),
- .package(url: "https://github.com/adobe/aepsdk-media-ios.git", .upToNextMajor(from: "4.0.0"))
+ .package(url: "https://github.com/adobe/aepsdk-edge-ios.git", .upToNextMajor(from: "4.0.0")),
+ .package(url: "https://github.com/adobe/aepsdk-edgeidentity-ios.git", .upToNextMajor(from: "4.0.0")),
+ .package(url: "https://github.com/adobe/aepsdk-edgemedia-ios.git", .upToNextMajor(from: "4.0.0"))
  ]
`}</code></pre>
    <Variant platform="android" section="import-dependency" repeat="4" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`Import extensions`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`  import com.adobe.marketing.mobile.MobileCore;
- import com.adobe.marketing.mobile.Identity;
- import com.adobe.marketing.mobile.Analytics;
- import com.adobe.marketing.mobile.Media;
+ import com.adobe.marketing.mobile.Edge;
+ import com.adobe.marketing.mobile.edge.identity.Identity;
+ import com.adobe.marketing.mobile.edge.media.Media;
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Register extensions`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`public class MainApp extends Application {
    private final String ENVIRONMENT_FILE_ID = "YOUR_APP_ENVIRONMENT_ID";

    @Override
    public void onCreate() {
        super.onCreate();

        MobileCore.setApplication(this);
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID);

-        List<Class<? extends Extension>> extensions = Arrays.asList(
-                Media.EXTENSION, Analytics.EXTENSION, Identity.EXTENSION);
+       List<Class<? extends Extension>> extensions = Arrays.asList(
+                Media.EXTENSION, Edge.EXTENSION, Identity.EXTENSION);
        MobileCore.registerExtensions(extensions, o -> {
            Log.d(LOG_TAG, "AEP Mobile SDK is initialized");
        });
    }
}
`}</code></pre>
    <Variant platform="ios" section="import-dependency" repeat="4" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`Import extensions`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`// AppDelegate.swift
import AEPCore
- import AEPIdentity
- import AEPAnalytics
- import AEPMedia
+ import AEPEdge
+ import AEPEdgeIdentity
+ import AEPEdgeMedia
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Register extensions`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`// AppDelegate.swift
func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
-  MobileCore.registerExtensions([Identity.self, Analytics.self, Media.self], {
+  MobileCore.registerExtensions([Edge.self, Identity.self, Media.self], {

    MobileCore.configureWith(appId: "yourEnvironmentID")
   })
   ...
}
`}</code></pre>
    <Variant platform="android" section="api-changes" repeat="15" mdxType="Variant" />
    <h4 {...{
      "id": "package-name"
    }}>{`package name`}</h4>
    <p>{`The Media for Edge Network extension uses the same class names as the Media Analytics extension. The Java package, however, is changed to `}<inlineCode parentName="p">{`com.adobe.marketing.mobile.edge.media`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- import com.adobe.marketing.mobile.Media;
- import com.adobe.marketing.mobile.MediaConstants;
- import com.adobe.marketing.mobile.MediaTracker;
+ import com.adobe.marketing.mobile.edge.media.Media;
+ import com.adobe.marketing.mobile.edge.media.MediaConstants;
+ import com.adobe.marketing.mobile.edge.media.MediaTracker;
`}</code></pre>
    <h4 {...{
      "id": "createmediaobject"
    }}>{`createMediaObject`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public static HashMap<String, Object> createMediaObject(String name,
-                                                         String id,
-                                                         double length,
-                                                         String streamType,
-                                                         MediaType mediaType);
+ public static HashMap<String, Object> createMediaObject(String name,
+                                                         String id,
+                                                         int length,
+                                                         String streamType,
+                                                         MediaType mediaType);
`}</code></pre>
    <h4 {...{
      "id": "createadbreakobject"
    }}>{`createAdBreakObject`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public static HashMap<String, Object> createAdBreakObject(String name, long position, double startTime);
+ public static HashMap<String, Object> createAdBreakObject(String name, int position, int startTime);
`}</code></pre>
    <h4 {...{
      "id": "createadobject"
    }}>{`createAdObject`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public static HashMap<String, Object> createAdObject(String name, String id, long position, double length);
+ public static HashMap<String, Object> createAdObject(String name, String id, int position, int length);
`}</code></pre>
    <h4 {...{
      "id": "createchapterobject"
    }}>{`createChapterObject`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public static HashMap<String, Object> createChapterObject(String name,
-                                                           long position,
-                                                           double length,
-                                                           double startTime);
+ public static HashMap<String, Object> createChapterObject(String name,
+                                                           int position,
+                                                           int length,
+                                                           int startTime);
`}</code></pre>
    <h4 {...{
      "id": "createqoeobject"
    }}>{`createQoeObject`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public static HashMap<String, Object> createQoEObject(long bitrate,
-                                                       double startupTime,
-                                                       double fps,
-                                                       long droppedFrames);
+ public static HashMap<String, Object> createQoEObject(int bitrate,
+                                                       int startupTime,
+                                                       int fps,
+                                                       int droppedFrames);
`}</code></pre>
    <h4 {...{
      "id": "updatecurrentplayhead"
    }}>{`updateCurrentPlayhead`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- public void updateCurrentPlayhead(double time);
+ public void updateCurrentPlayhead(int time);
`}</code></pre>
    <Variant platform="ios" section="api-changes" repeat="12" mdxType="Variant" />
    <h5 {...{
      "id": "createmediaobjectwith"
    }}>{`createMediaObjectWith`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- static func createMediaObjectWith(name: String, id: String, length: Double, streamType: String, mediaType: MediaType) -> [String: Any]?
+ static func createMediaObjectWith(name: String, id: String, length: Int, streamType: String, mediaType: MediaType) -> [String: Any]?
`}</code></pre>
    <h5 {...{
      "id": "createadbreakobjectwith"
    }}>{`createAdBreakObjectWith`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- static func createAdBreakObjectWith(name: String, position: Int, startTime: Double) -> [String: Any]?
+ static func createAdBreakObjectWith(name: String, position: Int, startTime: Int) -> [String: Any]?
`}</code></pre>
    <h5 {...{
      "id": "createadbjectwith"
    }}>{`createAdbjectWith`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- static func createAdObjectWith(name: String, id: String, position: Int, length: Double) -> [String: Any]?
+ static func createAdObjectWith(name: String, id: String, position: Int, length: Int) -> [String: Any]?
`}</code></pre>
    <h5 {...{
      "id": "createchapterobjectwith"
    }}>{`createChapterObjectWith`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- static func createChapterObjectWith(name: String, position: Int, length: Double, startTime: Double) -> [String: Any]?
+ static func createChapterObjectWith(name: String, position: Int, length: Int, startTime: Int) -> [String: Any]?
`}</code></pre>
    <h5 {...{
      "id": "createqoeobjectwith"
    }}>{`createQoEObjectWith`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- static func createQoEObjectWith(bitrate: Double, startupTime: Double, fps: Double, droppedFrames: Double) -> [String: Any]?
+ static func createQoEObjectWith(bitrate: Int, startupTime: Int, fps: Int, droppedFrames: Int) -> [String: Any]?
`}</code></pre>
    <h5 {...{
      "id": "updatecurrentplayhead-1"
    }}>{`updateCurrentPlayhead`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- func updateCurrentPlayhead(time: Double)
+ func updateCurrentPlayhead(time: Int)
`}</code></pre>
    <Variant platform="android" section="custom-interval" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`+ HashMap<String, Object> trackerConfig = new HashMap<>();
+ trackerConfig.put(MediaConstants.Config.AD_PING_INTERVAL, 1);

- MediaTracker tracker = Media.createTracker()
+ MediaTracker tracker = Media.createTrackerWith(trackerConfig);

HashMap<String, Object> mediaObject = Media.createMediaObject("name", "id", 30, "vod", Media.MediaType.Video);
- mediaObject.put(MediaConstants.MediaObjectKey.GRANULAR_AD_TRACKING, true)

HashMap<String, String> videoMetadata = new HashMap<String, String>();
videoMetadata.put(MediaConstants.VideoMetadataKeys.EPISODE, "Sample Episode");
videoMetadata.put(MediaConstants.VideoMetadataKeys.SHOW, "Sample Show");

tracker.trackSessionStart(mediaObject, videoMetadata)
`}</code></pre>
    <Variant platform="ios" section="custom-interval" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`+ var trackerConfig: [String: Any] = [:]
+ trackerConfig[MediaConstants.TrackerConfig.AD_PING_INTERVAL] = 1

- let tracker = Media.createTracker()
+ let tracker = Media.createTrackerWith(config: trackerConfig)

guard var mediaObject = Media.createMediaObjectWith(name: "name", id: "id", length: 30, streamType: "vod", mediaType: MediaType.Video) else {
  return
}
- mediaObject[MediaConstants.MediaObjectKey.GRANULAR_AD_TRACKING] = true

tracker.trackSessionStart(info: mediaObject, metadata: videoMetadata)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      