import DownloadSampleApplicationAndroid from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/download-sample-application/android.md";
import DownloadSampleApplicationIos from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/download-sample-application/ios.md";
import BuildXDMObjectsAndroid from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/build-xdm-objects/android.md";
import BuildXDMObjectsIos from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/build-xdm-objects/ios.md";
import OverrideDefaultDatasetAndroid from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/override-default-dataset/android.md";
import OverrideDefaultDatasetIos from "../../../../src/pages/resources/user-guides/getting-started-with-platform/tabs/sample-xdm-implementation/override-default-dataset/ios.md";
import * as React from 'react';
export default {
  DownloadSampleApplicationAndroid,
  DownloadSampleApplicationIos,
  BuildXDMObjectsAndroid,
  BuildXDMObjectsIos,
  OverrideDefaultDatasetAndroid,
  OverrideDefaultDatasetIos,
  React
};