import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="fcm" template="timer" repeat="15" mdxType="Variant" />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`The properties below define the payload sent to FCM:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payload Version`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Version of the payload assigned by the ACC authoring UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Template Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Informs the reader which properties may exist in the template object.`}<br />{`Timer template uses a value of "timer".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in the notification's title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate text for title of the notification after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_title`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in message body when notification is collapsed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate text for body of the notification after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expanded Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_ex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body of the message when the message is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate expanded body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_ex_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate text for body of the notification when the message is expanded after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_body`}</inlineCode>{`, `}<inlineCode parentName="td">{`adb_body_ex`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound played when notification is delivered.`}<br />{`Value should be the name of the sound file without its extension.`}<br />{`Sound file should be located in the `}<inlineCode parentName="td">{`assets/raw`}</inlineCode>{` directory of your app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI of an image to be shown when notification is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_image_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate URI of an image to be shown when notification is expanded after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link URI`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when user clicks the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Type of link represented in `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` - one of "WEBURL", "DEEPLINK", "DISMISS", or "OPENAPP".`}<br />{`Required if `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` is specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI or name for Large icon used in notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_small_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a small icon to use in the notification. The app's drawable resources are checked for an image file with the provided name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the notification's small icon.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Large Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_large_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI or name for a large icon to use in the notification. If the URI is valid, the remote image is downloaded and cached on the device to be used as the large icon. If a name is provided, the app's drawable resources are checked for an image file with the same name. If no valid URI or name is provided then no large icon is set on the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Channel ID`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The `}<a parentName="td" {...{
              "href": "https://developer.android.com/guide/topics/ui/notifiers/notifications#ManageChannels"
            }}>{`notification's channel id`}</a>{` (new in Android O). The app must create a channel with this channel ID before any notification with this channel ID is received. If you don't send this channel ID in the request, or if the channel ID provided has not yet been created by the app, FCM uses the channel ID specified in the app manifest.`}<br />{`If not provided in payload, SDK uses a "default" channel ID of value "General Notifications".`}<br />{`If < API 26 (Android O), this value is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Badge Count`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value to be used on app's badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Priority`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Designates the notification priority for Android - `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/android/app/NotificationChannel#setImportance(int)"
            }}>{`importance`}</a>{` for API >= 26 (Android 8.0) or `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/androidx/core/app/NotificationCompat.Builder#setPriority(int)"
            }}>{`priority`}</a>{` for API < 26.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Identifier used to replace existing notifications in the notification drawer. If not specified, each request creates a new notification. If specified and a notification with the same tag is already being shown, the new notification replaces the existing one in the notification drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sticky`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sticky`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean value represented as a string "true" or "false".`}<br />{`When set to false or unset, the notification is automatically dismissed when the user clicks it in the panel. When set to true, the notification persists even when the user clicks it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ticker`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_ticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the "ticker" text, which is sent to accessibility services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for notification's background.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00.`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Timer`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_tmr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the text of the timer overlay.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Timer duration`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tmr_dur`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If present, the timer on the notification will run for the number of seconds provided here.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Timer end timestamp`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tmr_end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If present, the timer on the notification will count down until this epoch time (in seconds).`}<br />{`Note - both `}<inlineCode parentName="td">{`adb_tmr_end`}</inlineCode>{` and `}<inlineCode parentName="td">{`adb_tmr_dur`}</inlineCode>{` are present, the duration will be used and `}<inlineCode parentName="td">{`adb_tmr_end`}</inlineCode>{` will be ignored.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <img {...{
      "src": "/client-sdks/assets/adb9257695c44774d9a2024791bf7b59/android_timer.gif",
      "width": "50%",
      "height": "50%"
    }}></img>
    <p>{`Below is a sample of what a payload might look like for a timer notification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message": {
    "token": "FCM_TOKEN",
    "android": {
      "collapse_key": "new message"
    },
    "data": {     
      "adb_version": "1",
      "adb_title": "Limited time offer!",
      "adb_title_alt": "You missed out on the sale.",
      "adb_body": "Don't miss out on your chance for deep discounts.",
      "adb_body_alt": "Our next flash sale will be sometime next month.",
      "adb_sound": "chaChing",
      "adb_icon": "ic_dollar",
      "adb_n_count": "1",
      "adb_n_priority": "PRIORITY_LOW",
      "adb_image": "https://bigboxretailer.com/sale.png",
      "adb_image_alt": "https://bigboxretailer.com/sale_ended.png",
      "adb_uri": "https://bigboxretailer.com/sale",
      "adb_a_type": "WEBURL",
      "adb_template_type": "timer",
      "adb_body_ex": "These discounts won't be around for long, click on the notification to go check out the sale.",
      "adb_body_ex_alt": "Sorry you missed us this time. Check back next month for some deep discounts.",
      "adb_clr_body": "00EE00",
      "adb_clr_title": "AABBCC",
      "adb_clr_icon": "123456",
      "adb_clr_bg": "000000",
      "adb_tmr_end": "1703462400",
      "adb_clr_tmr": "FFFFFF"
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "usage-recommendations"
    }}>{`Usage recommendations`}</h2>
    <p>{`The tables below contain guidelines for your push notification content. `}<strong parentName="p">{`These recommendations help your text and images display reliably across multiple devices.`}</strong>{` These values are guidelines only - you should still test a notification prior to sending it.`}</p>
    <h3 {...{
      "id": "audio-specifications"
    }}>{`Audio specifications`}</h3>
    <p>{`Custom sound files must be placed within the app's bundle in the `}<inlineCode parentName="p">{`res/raw`}</inlineCode>{` directory. Supported formats are `}<inlineCode parentName="p">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="p">{`wav`}</inlineCode>{`, or `}<inlineCode parentName="p">{`ogg`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "image-specifications"
    }}>{`Image specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Aspect ratios`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Image size range`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supported file types`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1:1, 3:2, 5:4, 4:3, 2:1, 16:9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`< 1 MB`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`PNG, JPG, WebP`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "text-guidelines"
    }}>{`Text guidelines`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Recommended max # of characters`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supports multiple lines?`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~35 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~110 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <Variant platform="apns" template="timer" repeat="16" mdxType="Variant" />
    <p>{`For full information on APNS payload keys, see `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/usernotifications/generating-a-remote-notification"
      }}>{`Apple's documentation`}</a>{`.`}</p>
    <p>{`The properties below define the payload sent to APNS:`}</p>
    <h2 {...{
      "id": "aps-properties"
    }}>{`APS properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.alert.title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in the notification's title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Subtitle`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.alert.subtitle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in subtitle of notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.alert.body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in message body when notification is collapsed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Notification Category`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The notification’s type. This string must correspond to the identifier of one of the `}<inlineCode parentName="td">{`UNNotificationCategory`}</inlineCode>{` objects you register at launch time.`}<br /><br />{`Value will always be "AEPNotification" to use an Adobe push template.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mutable content`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.mutable-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The notification service app extension flag. If the value is 1, the system passes the notification to your notification service app extension before delivery. Use your extension to modify the notification’s content.`}<br /><br />{`Value must always equal 1 so that the app's notification content service is called prior to the notification being displayed to the user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The name of a sound file in your app’s main bundle or in the Library/Sounds folder of your app’s container directory. Specify the string “default” to play the system sound. Use this key for regular notifications. For critical alerts, use the sound dictionary instead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Badge Count`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.badge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The number to display in a badge on your app’s icon. Specify 0 to remove the current badge, if any.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Notification Thread ID`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aps.thread-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An app-specific identifier for grouping related notifications. This value corresponds to the `}<inlineCode parentName="td">{`threadIdentifier`}</inlineCode>{` property in the `}<inlineCode parentName="td">{`UNNotificationContent`}</inlineCode>{` object.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "aepnotificationcontent-properties"
    }}>{`AEPNotificationContent properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payload Version`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Version of the payload assigned by the Adobe authoring UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Template Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Informs the reader which properties may exist in the template object.`}<br /><br />{`Timer template uses a value of "timer".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expanded Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title_ex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title of the message when the notification is expanded.`}<br /><br />{`If an expanded title is not provided, the value in `}<inlineCode parentName="td">{`aps.alert.title`}</inlineCode>{` will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expanded Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_ex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body of the message when the notification is expanded.`}<br /><br />{`If an expanded body is not provided, the value in `}<inlineCode parentName="td">{`aps.alert.body`}</inlineCode>{` will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI of an image to be shown when notification is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link URI`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when user clicks the notification body. For example, a deep link to your app or a URI to a webpage.`}<br /><br />{`If no value is provided, clicking on the notification will open the host application.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate text for title of the notification after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate text for body of the notification after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_media_alt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Alternate URI for an image shown when notification is expanded after the timer has expired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Timer duration`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tmr_dur`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If present, the timer on the notification will run for the number of seconds provided here.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Timer end timestamp`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tmr_end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If present, the timer on the notification will count down until this epoch time (in seconds). `}<br /><br /><strong parentName="td">{`Note`}</strong>{` - if both `}<inlineCode parentName="td">{`adb_tmr_end`}</inlineCode>{` and `}<inlineCode parentName="td">{`adb_tmr_dur`}</inlineCode>{` are present, `}<inlineCode parentName="td">{`adb_tmr_dur`}</inlineCode>{` will be used and `}<inlineCode parentName="td">{`adb_tmr_end`}</inlineCode>{` will be ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_title_ex`}</inlineCode>{`. Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode><br /><br />{`If no value is provided, the system `}<a parentName="td" {...{
              "href": "https://developer.apple.com/documentation/uikit/uicolor/3173131-label"
            }}>{`label color`}</a>{` will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_body_ex`}</inlineCode>{`. Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode><br /><br />{`If no value is provided, the system `}<a parentName="td" {...{
              "href": "https://developer.apple.com/documentation/uikit/uicolor/3173136-secondarylabel"
            }}>{`secondaryLabel color`}</a>{` will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for notification's background. Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode><br /><br />{`If no value is provided, the system `}<a parentName="td" {...{
              "href": "https://developer.apple.com/documentation/uikit/uicolor/3173140-systembackground"
            }}>{`systemBackground color`}</a>{` will be used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Timer`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_tmr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the text of the timer overlay. Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode><br /><br />{`If no value is provided, the system `}<a parentName="td" {...{
              "href": "https://developer.apple.com/documentation/uikit/uicolor/3173131-label"
            }}>{`label color`}</a>{` will be used.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-1"
    }}>{`Example`}</h2>
    <p><img parentName="p" {...{
        "src": "/client-sdks/assets/981405b568efa58cf030efa4cd786a89/timer.gif",
        "alt": "gif of sample timer notification"
      }}></img></p>
    <p>{`Below is a sample of what a payload might look like for a notification using the timer template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "aps" : {
        "alert" : {
            "title" : "Game day 🏀",
            "body" : "Don't miss the thrilling action tonight. Grab last-minute tickets now!"
        },
        "mutable-content" : 1,
        "category" : "AEPNotification"
    },
    "adb_template_type" : "timer",
    "adb_version" : "1.0",
    "adb_title_ex" : "💥 Last-minute deal alert!",
    "adb_body_ex" : "Save big on tonight's basketball game. Get your tickets ASAP!",
    "adb_title_alt" : "Missed out this time?",
    "adb_body_alt" : "Don't worry, more amazing offers are coming your way. Keep an eye out for more great offers soon",
    "adb_media" : "https://<URL_CONTAINING_INITIAL_IMAGE>",
    "adb_media_alt" : "https://<URL_CONTAINING_EXPIRED_IMAGE>",
    "adb_tmr_dur" : "10"
}
`}</code></pre>
    <h2 {...{
      "id": "usage-recommendations-1"
    }}>{`Usage recommendations`}</h2>
    <p>{`The tables below contain guidelines for your push notification content. `}<strong parentName="p">{`These recommendations help your text and images display reliably across multiple devices.`}</strong>{` These values are guidelines only - you should still test a notification prior to sending it.`}</p>
    <h3 {...{
      "id": "image-specifications-1"
    }}>{`Image specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Aspect ratio`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Image size range`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supported file types`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2:1 (e.g. - 400x200 px)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`50 KB to 1 MB`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`PNG, JPG, WebP`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "text-guidelines-1"
    }}>{`Text guidelines`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Recommended max # of characters`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supports multiple lines?`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`50`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Yes (2 lines max)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`450`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      