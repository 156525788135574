import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" api="payload-keys" repeat="3" mdxType="Variant" />
    <ul>
      <li parentName="ul">{`Adobe Journey Optimizer uses `}<a parentName="li" {...{
          "href": "https://firebase.google.com/docs/cloud-messaging/concept-options#data_messages"
        }}>{`data messages`}</a>{` with custom key-value pairs to send push notifications to client apps.`}</li>
      <li parentName="ul">{`When using `}<a parentName="li" {...{
          "href": "../android/manual-display-and-tracking.md"
        }}>{`manual display and tracking`}</a>{`, use the `}<a parentName="li" {...{
          "href": "../../public-classes/messaging-push-payload.md"
        }}>{`MessagingPushPayload`}</a>{` class to extract the payload values.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "message":{
      "android":{
         "collapse_key": "new_message",
         "priority": "HIGH",
         "data":{
            "adb_title":"Game Request",
            "adb_body":"Bob wants to play chess",
            "adb_sound" : "somesound_res",
            "adb_n_count" : "3",
            "adb_n_priority" : "PRIORITY_LOW",
            "adb_channel_id": "cid",
            "adb_icon" : "notification_icon",
            "adb_image": "www.imageUrl.com",           
            "adb_a_type": "DEEPLINK/WEBURL/OPENAPP",
            "adb_uri" : "deeplinkurl/weburl",
            "adb_act": "[{\\"label\\":\\"deeplink\\",\\"uri\\":\\"notificationapp://\\",\\"type\\":\\"DEEPLINK\\"},{\\"label\\":\\"weburl\\",\\"uri\\":\\"https://www.yahoo.com\\",\\"type\\":\\"WEBURL\\"}]",          
            "some_custom_data_key": "some data"
         }
      }
   }
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's sound.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification badge count.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's priority. For more information, please read the `}<a parentName="td" {...{
              "href": "https://firebase.google.com/docs/reference/fcm/rest/v1/projects.messages#notificationpriority"
            }}>{`Firebase documentation`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's channel ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The push notification's icon resource name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URL of the image to be displayed on the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`enum`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An enum that determines what type of action will be performed when the notification is clicked. It can be one of the following values: `}<inlineCode parentName="td">{`DEEPLINK`}</inlineCode>{`, `}<inlineCode parentName="td">{`WEBURL`}</inlineCode>{`, or `}<inlineCode parentName="td">{`OPENAPP`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URI used for deeplinking. The deeplink is used to open the appropriate webpage or app screen when the notification is clicked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array that contains the action object(s).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label for custom action button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URI for custom action button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`enum`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The action type for custom action button. It can be one of the following values: `}<inlineCode parentName="td">{`DEEPLINK`}</inlineCode>{`, `}<inlineCode parentName="td">{`WEBURL`}</inlineCode>{`, `}<inlineCode parentName="td">{`OPENAPP`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <Variant platform="ios" api="payload-keys" repeat="2" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "aps":{
      "alert":{
         "title": "Hello from CJM",
         "body": "Stay safe, wear a mask"
      },
      "sound": "dingDong",
      "badge":2,
      "mutable-content":1,
      "category": "iosCategory",
      "thread-id": "myGroup",
      "content-available":1
   },
   "some_custom_data_key": "some data",
   "adb_media": "www.imageUrl.com",
   "adb_a_type": "DEEPLINK/WEBURL/OPENAPP",
   "adb_uri": "deeplinkUrl/weburl",
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URL of the media. In this situation, media refers to either an image or a video. This URL can be used to download the rich media before showing the push notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URI used for deeplinking. The deeplink is used to open appropriate webpage or app screen when the notification is clicked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`enum`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An enum that determines what type of action will be performed when the notification is selected. It can be one of the following string values: `}<inlineCode parentName="td">{`DEEPLINK`}</inlineCode>{`, `}<inlineCode parentName="td">{`WEBURL`}</inlineCode>{`, `}<inlineCode parentName="td">{`OPENAPP`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array that contains the action object(s).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.aid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The ID for the action object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The name for the action object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_act.type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The type for the action object. It can be one of the following string values: `}<inlineCode parentName="td">{`DEEPLINK`}</inlineCode>{`, `}<inlineCode parentName="td">{`WEBURL`}</inlineCode>{`, `}<inlineCode parentName="td">{`OPENAPP`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      