import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="ios" extension="edge" version="3" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPEdge
let date = Date()
let formattedDate = XDMFormatters.dateToFullDateString(date)
let formattedDateWithMs = XDMFormatters.dateToISO8601String(date)
`}</code></pre>
    <Variant platform="ios" extension="edge" version="4" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPServices
let date = Date()
let formattedDate = date.getISO8601FullDate()
let formattedDateWithMs = date.getISO8601UTCDateWithMilliseconds()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      