import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineAlert = makeShortcode("InlineAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineAlert variant="warning" slots="text" mdxType="InlineAlert" />
    <p>{`The attach data feature applies `}<strong parentName="p">{`only`}</strong>{` to the Target extension.`}</p>
    <h4 {...{
      "id": "attach-additional-data-to-target-to-retrieve-location-events"
    }}>{`Attach additional data to Target to retrieve location events`}</h4>
    <p>{`The following sample shows how to attach data to all outgoing `}<inlineCode parentName="p">{`retrieveLocationContent`}</inlineCode>{` Target network requests. To create this type of rule, select your property in the Data Collection UI and complete the following steps.`}</p>
    <h4 {...{
      "id": "create-a-rule"
    }}>{`Create a rule`}</h4>
    <ol>
      <li parentName="ol">{`On the `}<strong parentName="li">{`Rules`}</strong>{` tab, select `}<strong parentName="li">{`Create New Rule`}</strong>{`.`}</li>
    </ol>
    <InlineAlert variant="info" slots="text" mdxType="InlineAlert" />
    <p>{`If you do not have existing rules for this property, the `}<strong parentName="p">{`Create New Rule`}</strong>{` button will be in the middle of the screen. If your property has rules, the button will be in the top right of the screen.`}</p>
    <h4 {...{
      "id": "select-an-event"
    }}>{`Select an event`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Give your rule an easily recognizable name in your list of rules.`}</p>
        <p parentName="li">{`In this example, the rule is named `}<strong parentName="p">{`Attach additional data to Target retrieve location Events`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the `}<strong parentName="p">{`Events`}</strong>{` section, select `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="p">{`Adobe Target`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Event Type`}</strong>{` dropdown list, select `}<strong parentName="p">{`Content Requested`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<strong parentName="p">{`Keep Changes`}</strong>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/d02c1251018001ab9920df00df70e329/5530d/target-attach-data-event-setup.webp 320w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/0c8fb/target-attach-data-event-setup.webp 640w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/94b1e/target-attach-data-event-setup.webp 1280w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/0b34d/target-attach-data-event-setup.webp 1920w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/d5269/target-attach-data-event-setup.webp 2560w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/d130a/target-attach-data-event-setup.webp 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/d02c1251018001ab9920df00df70e329/dd4a7/target-attach-data-event-setup.png 320w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/0f09e/target-attach-data-event-setup.png 640w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/bbbf7/target-attach-data-event-setup.png 1280w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/ac7a9/target-attach-data-event-setup.png 1920w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/c7a69/target-attach-data-event-setup.png 2560w", "/client-sdks/static/d02c1251018001ab9920df00df70e329/1cc5d/target-attach-data-event-setup.png 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/d02c1251018001ab9920df00df70e329/bbbf7/target-attach-data-event-setup.png",
            "alt": "target attach data event setup",
            "title": "target attach data event setup",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "define-the-action"
    }}>{`Define the action`}</h4>
    <ol>
      <li parentName="ol">{`Under the `}<strong parentName="li">{`Actions`}</strong>{` section, select `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="li">{`Mobile Core`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Action Type`}</strong>{` dropdown list, select `}<strong parentName="li">{`Attach Data`}</strong>{`.`}</li>
      <li parentName="ol">{`On the right pane, in the `}<strong parentName="li">{`JSON Payload`}</strong>{` field, type the data that will be added to this event.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Keep Changes`}</strong>{`.`}</li>
    </ol>
    <p>{`On the right pane, you can add a freeform JSON payload that adds data to an SDK event before an extension that is listening for this event can hear the event.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "request[*]": {
        "targetparams": {
            "profileparams": {
                "extraRetrieveLocationKey": "extraRetrieveLocationValue"
            },
            "mboxparameters": {
                "extraRetrieveLocationMboxKey": "extraRetrieveLocationMboxValue"
            }
        }
    }
}
`}</code></pre>
    <p>{`In the above example,  the JSON payload adds custom parameters to each of the Target retrieve location objects.`}</p>
    <h4 {...{
      "id": "save-the-rule-and-rebuild-your-property"
    }}>{`Save the rule and rebuild your property`}</h4>
    <p>{`After you complete your configuration, verify that your rule looks like the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/5530d/target-attach-data-rule-setup.webp 320w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/0c8fb/target-attach-data-rule-setup.webp 640w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/94b1e/target-attach-data-rule-setup.webp 1280w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/0b34d/target-attach-data-rule-setup.webp 1920w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/d5269/target-attach-data-rule-setup.webp 2560w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/d130a/target-attach-data-rule-setup.webp 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/dd4a7/target-attach-data-rule-setup.png 320w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/0f09e/target-attach-data-rule-setup.png 640w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/bbbf7/target-attach-data-rule-setup.png 1280w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/ac7a9/target-attach-data-rule-setup.png 1920w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/c7a69/target-attach-data-rule-setup.png 2560w", "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/1cc5d/target-attach-data-rule-setup.png 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/fc211e453ec4cf32e0836ae88c37959c/bbbf7/target-attach-data-rule-setup.png",
            "alt": "target attach data rule setup",
            "title": "target attach data rule setup",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save`}</strong></li>
      <li parentName="ol">{`Rebuild your mobile property and deploy it to the correct Environment.`}</li>
    </ol>
    <h4 {...{
      "id": "attach-additional-data-to-target-to-prefetch-content-events"
    }}>{`Attach additional data to Target to prefetch content events`}</h4>
    <p>{`The following sample shows how to add a custom mbox to prefetch in all outgoing `}<inlineCode parentName="p">{`prefetchContent`}</inlineCode>{` Target network requests. To create this type of rule, select your property in the Data Collection UI and complete the following steps.`}</p>
    <h4 {...{
      "id": "create-a-rule-1"
    }}>{`Create a rule`}</h4>
    <ol>
      <li parentName="ol">{`On the `}<strong parentName="li">{`Rules`}</strong>{` tab, select `}<strong parentName="li">{`Create New Rule`}</strong>{`.`}</li>
    </ol>
    <InlineAlert variant="info" slots="text" mdxType="InlineAlert" />
    <p>{`If you do not have existing rules for this property, the `}<strong parentName="p">{`Create New Rule`}</strong>{` button will be in the middle of the screen. If your property has rules, the button will be in the top right of the screen.`}</p>
    <h4 {...{
      "id": "select-an-event-1"
    }}>{`Select an event`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Give your rule an easily recognizable name in your list of rules.`}</p>
        <p parentName="li">{`In this example, the rule is named "Attach additional data to Target prefetch content Events".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the `}<strong parentName="p">{`Events`}</strong>{` section, select `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="p">{`Adobe Target`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Event Type`}</strong>{` dropdown list, select `}<strong parentName="p">{`Content Prefetched`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<strong parentName="p">{`Keep Changes`}</strong>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/5530d/target-attach-data-event-setup-prefetch.webp 320w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/0c8fb/target-attach-data-event-setup-prefetch.webp 640w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/94b1e/target-attach-data-event-setup-prefetch.webp 1280w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/0b34d/target-attach-data-event-setup-prefetch.webp 1920w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/d5269/target-attach-data-event-setup-prefetch.webp 2560w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/bf5ad/target-attach-data-event-setup-prefetch.webp 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/dd4a7/target-attach-data-event-setup-prefetch.png 320w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/0f09e/target-attach-data-event-setup-prefetch.png 640w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/bbbf7/target-attach-data-event-setup-prefetch.png 1280w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/ac7a9/target-attach-data-event-setup-prefetch.png 1920w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/c7a69/target-attach-data-event-setup-prefetch.png 2560w", "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/c6049/target-attach-data-event-setup-prefetch.png 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/d2a5491c018b4550cb0a7f98ba6521b3/bbbf7/target-attach-data-event-setup-prefetch.png",
            "alt": "target attach data event setup prefetch",
            "title": "target attach data event setup prefetch",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "define-the-action-1"
    }}>{`Define the action`}</h4>
    <ol>
      <li parentName="ol">{`Under the `}<strong parentName="li">{`Actions`}</strong>{` section, select `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="li">{`Mobile Core`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Action Type`}</strong>{` dropdown list, select `}<strong parentName="li">{`Attach Data`}</strong>{`.`}</li>
      <li parentName="ol">{`On the right pane, in the `}<strong parentName="li">{`JSON Payload`}</strong>{` field, type the data that will be added to this event.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Keep Changes`}</strong>{`.`}</li>
    </ol>
    <p>{`On the right pane, you can add a freeform JSON payload that adds data to an SDK event before an extension that is listening for this event can hear the event.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "prefetch[*]": {
        "targetparams": {
            "profileparams": {
                "extraPrefetchProfileKey": "extraPrefetchProfileValue"
            },
            "mboxparameters": {
                "extraPrefetchMboxKey": "extraPrefetchMboxValue"
            }
        }
    }
}
`}</code></pre>
    <p>{`In the above example, the JSON payload adds custom mbox parameters to each of the Target prefetch objects.`}</p>
    <h4 {...{
      "id": "save-the-rule-and-rebuild-your-property-1"
    }}>{`Save the rule and rebuild your property`}</h4>
    <p>{`After you complete your configuration, verify that your rule looks like the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.62500000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/cff18317abc754f74499dff13982ec5a/5530d/target-attach-data-rule-setup-prefetch.webp 320w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/0c8fb/target-attach-data-rule-setup-prefetch.webp 640w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/94b1e/target-attach-data-rule-setup-prefetch.webp 1280w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/0b34d/target-attach-data-rule-setup-prefetch.webp 1920w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/d5269/target-attach-data-rule-setup-prefetch.webp 2560w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/4a0d2/target-attach-data-rule-setup-prefetch.webp 2796w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/cff18317abc754f74499dff13982ec5a/dd4a7/target-attach-data-rule-setup-prefetch.png 320w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/0f09e/target-attach-data-rule-setup-prefetch.png 640w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/bbbf7/target-attach-data-rule-setup-prefetch.png 1280w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/ac7a9/target-attach-data-rule-setup-prefetch.png 1920w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/c7a69/target-attach-data-rule-setup-prefetch.png 2560w", "/client-sdks/static/cff18317abc754f74499dff13982ec5a/8af76/target-attach-data-rule-setup-prefetch.png 2796w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/cff18317abc754f74499dff13982ec5a/bbbf7/target-attach-data-rule-setup-prefetch.png",
            "alt": "target attach data rule setup prefetch",
            "title": "target attach data rule setup prefetch",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save`}</strong></li>
      <li parentName="ol">{`Rebuild your mobile property and deploy it to the correct Environment.`}</li>
    </ol>
    <h4 {...{
      "id": "attach-additional-data-to-target-location-clicked-events"
    }}>{`Attach additional data to Target location-clicked events`}</h4>
    <p>{`The following sample shows how to add additional mbox and profile parameters in all outgoing `}<inlineCode parentName="p">{`locationClicked`}</inlineCode>{` Target network requests. To create this type of rule, select your property in the Data Collection UI and complete the following steps.`}</p>
    <h4 {...{
      "id": "create-a-rule-2"
    }}>{`Create a rule`}</h4>
    <ol>
      <li parentName="ol">{`On the `}<strong parentName="li">{`Rules`}</strong>{` tab, select `}<strong parentName="li">{`Create New Rule`}</strong>{`.`}</li>
    </ol>
    <InlineAlert variant="info" slots="text" mdxType="InlineAlert" />
    <p>{`If you do not have existing rules for this property, the `}<strong parentName="p">{`Create New Rule`}</strong>{` button will be in the middle of the screen. If your property has rules, the button will be in the top right of the screen.`}</p>
    <h4 {...{
      "id": "select-an-event-2"
    }}>{`Select an event`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Give your rule an easily recognizable name in your list of rules.`}</p>
        <p parentName="li">{`In this example, the rule is named "Attach additional data to Target location clicked Events".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the `}<strong parentName="p">{`Events`}</strong>{` section, select `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="p">{`Adobe Target`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Event Type`}</strong>{` dropdown list, select `}<strong parentName="p">{`Location Clicked`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<strong parentName="p">{`Keep Changes`}</strong>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/5530d/target-attach-data-event-setup-location-clicked.webp 320w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/0c8fb/target-attach-data-event-setup-location-clicked.webp 640w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/94b1e/target-attach-data-event-setup-location-clicked.webp 1280w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/0b34d/target-attach-data-event-setup-location-clicked.webp 1920w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/d5269/target-attach-data-event-setup-location-clicked.webp 2560w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/bf5ad/target-attach-data-event-setup-location-clicked.webp 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/dd4a7/target-attach-data-event-setup-location-clicked.png 320w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/0f09e/target-attach-data-event-setup-location-clicked.png 640w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/bbbf7/target-attach-data-event-setup-location-clicked.png 1280w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/ac7a9/target-attach-data-event-setup-location-clicked.png 1920w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/c7a69/target-attach-data-event-setup-location-clicked.png 2560w", "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/c6049/target-attach-data-event-setup-location-clicked.png 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/2c001aed0019b8abfe537fc6c32441b8/bbbf7/target-attach-data-event-setup-location-clicked.png",
            "alt": "target attach data event setup location clicked",
            "title": "target attach data event setup location clicked",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "define-the-action-2"
    }}>{`Define the action`}</h4>
    <ol>
      <li parentName="ol">{`Under the `}<strong parentName="li">{`Actions`}</strong>{` section, select `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="li">{`Mobile Core`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Action Type`}</strong>{` dropdown list, select `}<strong parentName="li">{`Attach Data`}</strong>{`.`}</li>
      <li parentName="ol">{`On the right pane, in the `}<strong parentName="li">{`JSON Payload`}</strong>{` field, type the data that will be added to this event.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Keep Changes`}</strong>{`.`}</li>
    </ol>
    <p>{`On the right pane, you can add a freeform JSON payload that adds data to an SDK event before an extension that is listening for this event can hear the event. In this example, custom mbox and profile parameters are added to the event before the Target extension processes it. The added mbox and profile parameters will now be added on outgoing Target location clicked requests.`}</p>
    <p>{`In the following example, `}<strong parentName="p">{`extraKey`}</strong>{` and `}<strong parentName="p">{`extraKey2`}</strong>{` are added to the profile parameters. A key named `}<inlineCode parentName="p">{`customMboxParameter`}</inlineCode>{` and a data element that was defined for the `}<strong parentName="p">{`OS version`}</strong>{` are added to the mbox parameters of the Target event. Values for the new keys can either be hardcoded in the rule or be dynamically determined by the SDK when this event processes by using data elements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.6875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/e956ba5074cfb4c4b293308338da501f/5530d/target-attach-data-json-example-location-clicked.webp 320w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0c8fb/target-attach-data-json-example-location-clicked.webp 640w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/94b1e/target-attach-data-json-example-location-clicked.webp 1280w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0b34d/target-attach-data-json-example-location-clicked.webp 1920w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/d5269/target-attach-data-json-example-location-clicked.webp 2560w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bf5ad/target-attach-data-json-example-location-clicked.webp 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/e956ba5074cfb4c4b293308338da501f/dd4a7/target-attach-data-json-example-location-clicked.png 320w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0f09e/target-attach-data-json-example-location-clicked.png 640w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bbbf7/target-attach-data-json-example-location-clicked.png 1280w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/ac7a9/target-attach-data-json-example-location-clicked.png 1920w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/c7a69/target-attach-data-json-example-location-clicked.png 2560w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/c6049/target-attach-data-json-example-location-clicked.png 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bbbf7/target-attach-data-json-example-location-clicked.png",
            "alt": "target attach data json example location clicked",
            "title": "target attach data json example location clicked",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`The following example shows how the data element for this OS version was created.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/720566973f407bf3765e9d677b750c37/5530d/target-attach-data-data-element-setup.webp 320w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/0c8fb/target-attach-data-data-element-setup.webp 640w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/94b1e/target-attach-data-data-element-setup.webp 1280w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/0b34d/target-attach-data-data-element-setup.webp 1920w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/d5269/target-attach-data-data-element-setup.webp 2560w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/d130a/target-attach-data-data-element-setup.webp 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/720566973f407bf3765e9d677b750c37/dd4a7/target-attach-data-data-element-setup.png 320w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/0f09e/target-attach-data-data-element-setup.png 640w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/bbbf7/target-attach-data-data-element-setup.png 1280w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/ac7a9/target-attach-data-data-element-setup.png 1920w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/c7a69/target-attach-data-data-element-setup.png 2560w", "/client-sdks/static/720566973f407bf3765e9d677b750c37/1cc5d/target-attach-data-data-element-setup.png 2806w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/720566973f407bf3765e9d677b750c37/bbbf7/target-attach-data-data-element-setup.png",
            "alt": "target attach data data element setup",
            "title": "target attach data data element setup",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "save-the-rule-and-rebuild-your-property-2"
    }}>{`Save the rule and rebuild your property`}</h4>
    <p>{`After you complete your configuration, verify that your rule looks like the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/5530d/target-attach-data-rule-setup-location-clicked.webp 320w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/0c8fb/target-attach-data-rule-setup-location-clicked.webp 640w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/94b1e/target-attach-data-rule-setup-location-clicked.webp 1280w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/0b34d/target-attach-data-rule-setup-location-clicked.webp 1920w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/d5269/target-attach-data-rule-setup-location-clicked.webp 2560w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/bf5ad/target-attach-data-rule-setup-location-clicked.webp 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/dd4a7/target-attach-data-rule-setup-location-clicked.png 320w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/0f09e/target-attach-data-rule-setup-location-clicked.png 640w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/bbbf7/target-attach-data-rule-setup-location-clicked.png 1280w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/ac7a9/target-attach-data-rule-setup-location-clicked.png 1920w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/c7a69/target-attach-data-rule-setup-location-clicked.png 2560w", "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/c6049/target-attach-data-rule-setup-location-clicked.png 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/1b58fadfe6f4a49d2e3ed33fc317a34c/bbbf7/target-attach-data-rule-setup-location-clicked.png",
            "alt": "target attach data rule setup location clicked",
            "title": "target attach data rule setup location clicked",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save`}</strong></li>
      <li parentName="ol">{`Rebuild your mobile property and deploy it to the correct Environment.`}</li>
    </ol>
    <h4 {...{
      "id": "attach-additional-data-to-target-location-displayed-events"
    }}>{`Attach additional data to Target location-displayed events`}</h4>
    <p>{`The following sample shows how to add additional mbox and profile parameters in all outgoing `}<inlineCode parentName="p">{`locationDisplayed`}</inlineCode>{` Target network requests. To create this type of rule, select your property in the Data Collection UI and complete the following steps.`}</p>
    <h4 {...{
      "id": "create-a-rule-3"
    }}>{`Create a rule`}</h4>
    <ol>
      <li parentName="ol">{`On the `}<strong parentName="li">{`Rules`}</strong>{` tab, select `}<strong parentName="li">{`Create New Rule`}</strong>{`.`}</li>
    </ol>
    <InlineAlert variant="info" slots="text" mdxType="InlineAlert" />
    <p>{`If you do not have existing rules for this property, the `}<strong parentName="p">{`Create New Rule`}</strong>{` button will be in the middle of the screen. If your property has rules, the button will be in the top right of the screen.`}</p>
    <h4 {...{
      "id": "select-an-event-3"
    }}>{`Select an event`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Give your rule an easily recognizable name in your list of rules.`}</p>
        <p parentName="li">{`In this example, the rule is named "Attach additional data to Target location displayed Events".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the `}<strong parentName="p">{`Events`}</strong>{` section, select `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="p">{`Adobe Target`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Event Type`}</strong>{` dropdown list, select `}<strong parentName="p">{`Location Displayed`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<strong parentName="p">{`Keep Changes`}</strong>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/5530d/target-attach-data-event-setup-location-displayed.webp 320w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/0c8fb/target-attach-data-event-setup-location-displayed.webp 640w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/94b1e/target-attach-data-event-setup-location-displayed.webp 1280w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/0b34d/target-attach-data-event-setup-location-displayed.webp 1920w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/d5269/target-attach-data-event-setup-location-displayed.webp 2560w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/4a0d2/target-attach-data-event-setup-location-displayed.webp 2796w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/dd4a7/target-attach-data-event-setup-location-displayed.png 320w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/0f09e/target-attach-data-event-setup-location-displayed.png 640w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/bbbf7/target-attach-data-event-setup-location-displayed.png 1280w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/ac7a9/target-attach-data-event-setup-location-displayed.png 1920w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/c7a69/target-attach-data-event-setup-location-displayed.png 2560w", "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/8af76/target-attach-data-event-setup-location-displayed.png 2796w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/a37d91f88a3b772a2c8f09c3b5aa03cc/bbbf7/target-attach-data-event-setup-location-displayed.png",
            "alt": "target attach data event setup location displayed",
            "title": "target attach data event setup location displayed",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "define-the-action-3"
    }}>{`Define the action`}</h4>
    <ol>
      <li parentName="ol">{`Under the `}<strong parentName="li">{`Actions`}</strong>{` section, select `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="li">{`Mobile Core`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Action Type`}</strong>{` dropdown list, select `}<strong parentName="li">{`Attach Data`}</strong>{`.`}</li>
      <li parentName="ol">{`On the right pane, in the `}<strong parentName="li">{`JSON Payload`}</strong>{` field, type the data that will be added to this event.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Keep Changes`}</strong>{`.`}</li>
    </ol>
    <p>{`On the right pane, you can add a freeform JSON payload that adds data to an SDK event before an extension that is listening for this event can hear the event. In this example, custom mbox and profile parameters are added to the event before the Target extension processes it. The added mbox and profile parameters will now be added on outgoing Target location displayed requests.`}</p>
    <p>{`In the following example, `}<strong parentName="p">{`extraKey`}</strong>{` and `}<strong parentName="p">{`extraKey2`}</strong>{` are added to the profile parameters. A key named `}<inlineCode parentName="p">{`customMboxParameter`}</inlineCode>{` and a data element that was defined for the OS version are added to the mbox parameters of the Target event. Values for the new keys can either be hardcoded in the rule or be dynamically determined by the SDK when this event processes by using data elements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.6875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/e956ba5074cfb4c4b293308338da501f/5530d/target-attach-data-json-example-location-clicked.webp 320w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0c8fb/target-attach-data-json-example-location-clicked.webp 640w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/94b1e/target-attach-data-json-example-location-clicked.webp 1280w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0b34d/target-attach-data-json-example-location-clicked.webp 1920w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/d5269/target-attach-data-json-example-location-clicked.webp 2560w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bf5ad/target-attach-data-json-example-location-clicked.webp 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/e956ba5074cfb4c4b293308338da501f/dd4a7/target-attach-data-json-example-location-clicked.png 320w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/0f09e/target-attach-data-json-example-location-clicked.png 640w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bbbf7/target-attach-data-json-example-location-clicked.png 1280w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/ac7a9/target-attach-data-json-example-location-clicked.png 1920w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/c7a69/target-attach-data-json-example-location-clicked.png 2560w", "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/c6049/target-attach-data-json-example-location-clicked.png 2798w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/e956ba5074cfb4c4b293308338da501f/bbbf7/target-attach-data-json-example-location-clicked.png",
            "alt": "target attach data json example location clicked",
            "title": "target attach data json example location clicked",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "save-the-rule-and-rebuild-your-property-3"
    }}>{`Save the rule and rebuild your property`}</h4>
    <p>{`After you complete your configuration, verify that your rule looks like the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/5530d/target-attach-data-rule-setup-location-displayed.webp 320w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/0c8fb/target-attach-data-rule-setup-location-displayed.webp 640w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/94b1e/target-attach-data-rule-setup-location-displayed.webp 1280w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/0b34d/target-attach-data-rule-setup-location-displayed.webp 1920w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/d5269/target-attach-data-rule-setup-location-displayed.webp 2560w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/9db58/target-attach-data-rule-setup-location-displayed.webp 2800w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/dd4a7/target-attach-data-rule-setup-location-displayed.png 320w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/0f09e/target-attach-data-rule-setup-location-displayed.png 640w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/bbbf7/target-attach-data-rule-setup-location-displayed.png 1280w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/ac7a9/target-attach-data-rule-setup-location-displayed.png 1920w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/c7a69/target-attach-data-rule-setup-location-displayed.png 2560w", "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/eb2a1/target-attach-data-rule-setup-location-displayed.png 2800w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/c3c7d49ac53e543c46f6606e5233d406/bbbf7/target-attach-data-rule-setup-location-displayed.png",
            "alt": "target attach data rule setup location displayed",
            "title": "target attach data rule setup location displayed",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save`}</strong></li>
      <li parentName="ol">{`Rebuild your mobile property and deploy it to the correct Environment.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      