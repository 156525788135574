import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant lang="java" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MainApp extends Application {
    private static final String ENVIRONMENT_FILE_ID = "<your_environment_file_id>";

    @Override
    public void onCreate() {
        super.onCreate();
        MobileCore.setApplication(this);
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID);
        List<Class<? extends Extension>> extensions = new ArrayList<>();
        extensions.add(Lifecycle.EXTENSION);
        extensions.add(Signal.EXTENSION);
        extensions.add(UserProfile.EXTENSION);
        extensions.add(Assurance.EXTENSION);
        extensions.add(Identity.EXTENSION);
        MobileCore.registerExtensions(extensions, o -> {
            Log.d(LOG_TAG, "Adobe Experience Platform Mobile SDK is initialized");
        });
}
}


`}</code></pre>
    <Variant lang="kotlin" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class MyApp : Application() {
    val ENVIRONMENT_FILE_ID = "<your_environment_file_id>"

    override fun onCreate() {
        super.onCreate()
        MobileCore.setApplication(this)
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID)
        val extensions = listOf(
            Identity.EXTENSION,
            Signal.EXTENSION,
            Lifecycle.EXTENSION,
            UserProfile.EXTENSION,
            Assurance.EXTENSION
            )
        MobileCore.registerExtensions(extensions) {
            Log.d(LOG_TAG, "Adobe Experience Platform Mobile SDK is initialized")
        }
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      