import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" function="register" repeat="5" mdxType="Variant" />
    <p>{`On Android, the delegate is registered with the Adobe Service Provider. The `}<inlineCode parentName="p">{`ServiceProvider`}</inlineCode>{` class maintains an optional property that holds reference to the `}<inlineCode parentName="p">{`FullscreenMessaageDelegate`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// defined in public class ServiceProvider 
public void setMessageDelegate(MessagingDelegate messageDelegate)
`}</code></pre>
    <p>{`On Android, the delegate is registered with Mobile Core. The `}<inlineCode parentName="p">{`MobileCore`}</inlineCode>{` contains a method to set the`}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` withing the `}<inlineCode parentName="p">{`ServiceProvider`}</inlineCode>{` class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`CustomDelegate myMessagingDelegate = new CustomDelegate();

MobileCore.setMessagingDelegate(myMessagingDelegate);
`}</code></pre>
    <Variant platform="ios" function="register" repeat="5" mdxType="Variant" />
    <p>{`On iOS, the delegate is registered with Mobile Core. The `}<inlineCode parentName="p">{`MobileCore`}</inlineCode>{` framework maintains an optional property that holds reference to the `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`/// defined in MobileCore.swift
@objc public static var messagingDelegate: MessagingDelegate?
`}</code></pre>
    <p>{`Assuming that `}<inlineCode parentName="p">{`InAppMessagingHandler`}</inlineCode>{` is a class that implements `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{`, execute the following code to set the delegate in `}<inlineCode parentName="p">{`MobileCore`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let myMessagingDelegate = InAppMessagingHandler()   

MobileCore.messagingDelegate = myMessagingDelegate
`}</code></pre>
    <Variant platform="android" function="messaging-delegate" repeat="3" mdxType="Variant" />
    <p>{`The `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` interface, which is implemented in the `}<inlineCode parentName="p">{`AEPServices`}</inlineCode>{` framework, is defined below:`}</p>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`/**
 * UI Message delegate which is used to listen for current message lifecycle events and control if
 * the message should be displayed.
 */
public interface MessagingDelegate {
    /**
     * Invoked when a message is displayed.
     *
     * @param message {@link FullscreenMessage} that is being displayed
     */
    default void onShow(final FullscreenMessage message) {
        Log.debug(ServiceConstants.LOG_TAG, "MessagingDelegate", "Fullscreen message shown.");
    }

    /**
     * Invoked when a message is dismissed.
     *
     * @param message {@link FullscreenMessage} that is being dismissed
     */
    default void onDismiss(final FullscreenMessage message) {
        Log.debug(ServiceConstants.LOG_TAG, "MessagingDelegate", "Fullscreen message dismissed.");
    }

    /**
     * Used to determine if a message should be shown.
     *
     * @param message {@link FullscreenMessage} that is about to get displayed
     * @return true if the message should be displayed, false otherwise
     */
    boolean shouldShowMessage(final FullscreenMessage message);

    /**
     * Called when the {@link FullscreenMessage} loads a url.
     *
     * @param url {@code String} being loaded by the {@code FullscreenMessage}
     * @param message {@link FullscreenMessage} loading a url {@code String}
     */
    default void urlLoaded(final String url, final FullscreenMessage message) {
        Log.debug(
                ServiceConstants.LOG_TAG,
                "MessagingDelegate",
                "Fullscreen message loaded url: %s",
                url);
    }
}
`}</code></pre>
    <Variant platform="ios" function="messaging-delegate" repeat="3" mdxType="Variant" />
    <p>{`On iOS, the `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` protocol, which is implemented in the `}<inlineCode parentName="p">{`AEPServices`}</inlineCode>{` framework, is defined below:`}</p>
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`/// UI Message delegate which is used to listen for current message lifecycle events
@objc(AEPMessagingDelegate)
public protocol MessagingDelegate {
    /// Invoked when a message is displayed
    /// - Parameters:
    ///     - message: UIMessaging message that is being displayed
    @objc(onShow:)
    func onShow(message: Showable)

    /// Invoked when a message is dismissed
    /// - Parameters:
    ///     - message: UIMessaging message that is being dismissed
    @objc(onDismiss:)
    func onDismiss(message: Showable)

    /// Used to find whether messages should be shown or not
    ///
    /// IMPORTANT! - this method is called on a background thread. 
    /// Any direct interactions with the Message's WKWebView made by the delegate
    /// should be dispatched back to the main thread.
    ///
    /// - Parameters:
    ///     - message: UIMessaging message that is about to get displayed
    /// - Returns: true if the message should be shown else false
    @objc(shouldShowMessage:)
    func shouldShowMessage(message: Showable) -> Bool

    /// Called when \`message\` loads a URL
    /// - Parameters:
    ///     - url: the \`URL\` being loaded by the \`message\`
    ///     - message: the Message loading a \`URL\`
    @objc(urlLoaded:byMessage:)
    optional func urlLoaded(_ url: URL, byMessage message: Showable)
}
`}</code></pre>
    <Variant platform="android" function="using-object" repeat="4" mdxType="Variant" />
    <p>{`The user interface methods (except for `}<inlineCode parentName="p">{`onShowFailure()`}</inlineCode>{`) in a `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` implementation will be passed an `}<inlineCode parentName="p">{`AEPMessage`}</inlineCode>{` object. An `}<inlineCode parentName="p">{`AEPMessage`}</inlineCode>{` object is the Android Core implementation of the `}<inlineCode parentName="p">{`FullscreenMessage`}</inlineCode>{` interface. It contains a reference to the parent `}<inlineCode parentName="p">{`Message`}</inlineCode>{` class and is your primary way to interact with the message.`}</p>
    <p>{`A reference to the `}<inlineCode parentName="p">{`AEPMessage`}</inlineCode>{` object can be obtained by calling `}<inlineCode parentName="p">{`fullscreenMessage.getParent()`}</inlineCode>{` . An example of how to access the `}<inlineCode parentName="p">{`Message`}</inlineCode>{` in the `}<inlineCode parentName="p">{`onShow`}</inlineCode>{` delegate method can be seen below:`}</p>
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public void onShow(FullscreenMessage fullscreenMessage) {
  Message message = (Message) fullscreenMessage.getParent();
  System.out.println("message was shown: " + message.id);
}
`}</code></pre>
    <Variant platform="ios" function="using-object" repeat="6" mdxType="Variant" />
    <p>{`Each of the methods implemented in the `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` will be passed a `}<a parentName="p" {...{
        "href": "https://github.com/adobe/aepsdk-core-ios/blob/main/AEPServices/Sources/ui/Showable.swift"
      }}><inlineCode parentName="a">{`Showable`}</inlineCode></a>{` object. In the AEPMessaging SDK, the class implementing `}<inlineCode parentName="p">{`Showable`}</inlineCode>{` is `}<a parentName="p" {...{
        "href": "https://github.com/adobe/aepsdk-core-ios/blob/main/AEPServices/Sources/ui/fullscreen/FullscreenMessage.swift"
      }}><inlineCode parentName="a">{`FullscreenMessage`}</inlineCode></a>{`. A `}<inlineCode parentName="p">{`FullscreenMessage`}</inlineCode>{` object is wrapped in the `}<a parentName="p" {...{
        "href": "../public-classes-enums.md"
      }}><inlineCode parentName="a">{`Message`}</inlineCode></a>{` class, and is your primary way to interact with the message.`}</p>
    <p>{`To get a reference to the `}<inlineCode parentName="p">{`Message`}</inlineCode>{` object:`}</p>
    <ol>
      <li parentName="ol">{`Convert the `}<inlineCode parentName="li">{`Showable`}</inlineCode>{` message parameter to `}<inlineCode parentName="li">{`FullscreenMessage`}</inlineCode></li>
      <li parentName="ol">{`Access the `}<inlineCode parentName="li">{`parent`}</inlineCode>{` variable (note that `}<inlineCode parentName="li">{`parent`}</inlineCode>{` is variable defined in `}<inlineCode parentName="li">{`FullscreenMessage+Message.swift`}</inlineCode>{`, an extension in the AEPMessaging framework)`}</li>
    </ol>
    <p>{`An example of how to access the `}<inlineCode parentName="p">{`Message`}</inlineCode>{` in the `}<inlineCode parentName="p">{`onShow`}</inlineCode>{` delegate method can be seen below:`}</p>
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func onShow(message: Showable) {
    let fullscreenMessage = message as? FullscreenMessage
    let message = fullscreenMessage?.parent
    print("message was shown \\(message?.id ?? "undefined")")
}
`}</code></pre>
    <Variant platform="android" function="controlling-message" repeat="7" mdxType="Variant" />
    <p>{`If a custom  `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` has been set in the `}<inlineCode parentName="p">{`ServiceProvider`}</inlineCode>{`, this delegate's `}<inlineCode parentName="p">{`shouldShowMessage`}</inlineCode>{` method will be called prior to displaying an in-app message for which the end user has qualified. You are responsible for returning `}<inlineCode parentName="p">{`true`}</inlineCode>{` if the message should be shown, or `}<inlineCode parentName="p">{`false`}</inlineCode>{` if the message should be suppressed.`}</p>
    <p>{`An example of when you may choose to suppress an in-app message due to the status of some other workflow within the app can be seen below:`}</p>
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
   if (someOtherWorkflowStatus == "inProgress") {
        return false;
    }
    return true;
}
`}</code></pre>
    <p>{`Another option is to store a reference to the  `}<inlineCode parentName="p">{`FullscreenMessage`}</inlineCode>{` object, and call the `}<inlineCode parentName="p">{`show()`}</inlineCode>{` method on it at a later time.`}</p>
    <p>{`Continuing with the above example, after you have stored the message that was triggered initially, you can choose to show it upon completion of the other workflow:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Message currentMessage = null;
String anotherWorkflowStatus;

public void otherWorkflowFinished() {
    anotherWorkflowStatus = "complete";
    currentMessage.show();
}

@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
   if (someOtherWorkflowStatus.equals("inProgress")) {
     // store the current message for later use
     currentMessage = (Message) fullscreenMessage.getParent();
     return false;
   }
  
  return true;
}
`}</code></pre>
    <Variant platform="ios" function="controlling-message" repeat="7" mdxType="Variant" />
    <p>{`If a `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` has been provided to `}<inlineCode parentName="p">{`MobileCore`}</inlineCode>{`, the delegate's `}<inlineCode parentName="p">{`shouldShowMessage`}</inlineCode>{` method will be called prior to displaying an in-app message for which the end user has qualified. You are responsible for returning `}<inlineCode parentName="p">{`true`}</inlineCode>{` if the message should be shown, or `}<inlineCode parentName="p">{`false`}</inlineCode>{` if the message should be suppressed.`}</p>
    <p>{`An example of when you may choose to suppress an in-app message due to the status of some other workflow within the app can be seen below:`}</p>
    <h4 {...{
      "id": "swift-3"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func shouldShowMessage(message: Showable) -> Bool {
    if someOtherWorkflowStatus == "inProgress" {
        return false
    }

    return true
}
`}</code></pre>
    <p>{`Another option is to store a reference to the `}<inlineCode parentName="p">{`Message`}</inlineCode>{` object, and call the `}<inlineCode parentName="p">{`show()`}</inlineCode>{` method on it at a later time.`}</p>
    <p>{`Continuing with the above example, after you have stored the message that was triggered initially, you can choose to show it upon completion of the other workflow:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var currentMessage: Message?

func otherWorkflowFinished() {
    anotherWorkflowStatus = "complete"
    currentMessage?.show()
}

func shouldShowMessage(message: Showable) -> Bool {
    if someOtherWorkflowStatus == "inProgress" {        
        let fullscreenMessage = message as? FullscreenMessage

        // store the current message for later use
        currentMessage = fullscreenMessage?.parent

        return false
    }

    return true
}
`}</code></pre>
    <Variant platform="android" function="integrate-message" repeat="4" mdxType="Variant" />
    <p>{`If you would like to manually integrate the `}<inlineCode parentName="p">{`View`}</inlineCode>{` that contains the UI for an in-app message, you can do so by accessing the `}<inlineCode parentName="p">{`WebView`}</inlineCode>{` directly in a `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` method.  `}</p>
    <p>{`In the example below, you can decide whether or not the in-app message should be directly integrated into your existing UI. If so, you capture a reference to the message's `}<inlineCode parentName="p">{`WebView`}</inlineCode>{` and return `}<inlineCode parentName="p">{`false`}</inlineCode>{` to prevent the message from being shown by the SDK:`}</p>
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`private Message currentMessage = null;
private boolean shouldIntegrateMessageDirectly = true;
private MessageWebView inAppMessageView;

@Override
public boolean shouldShowMessage(FullscreenMessage fullscreenMessage) {
  if (shouldIntegrateMessageDirectly) {
    this.currentMessage = (Message) fullscreenMessage.getParent();
    
    // cast to MessageWebView to access the startInAppMessage function
    inAppMessageView = (MessageWebView) currentMessage.getWebView();
    
    return false;
  }
  
  return true;
}
`}</code></pre>
    <Variant platform="ios" function="integrate-message" repeat="4" mdxType="Variant" />
    <p>{`If you would like to manually integrate the `}<inlineCode parentName="p">{`View`}</inlineCode>{` that contains the UI for an in-app message, you can do so by accessing the `}<inlineCode parentName="p">{`WKWebView`}</inlineCode>{` directly in a `}<inlineCode parentName="p">{`MessagingDelegate`}</inlineCode>{` method.  `}</p>
    <p>{`IMPORTANT! - The `}<inlineCode parentName="p">{`shouldShowMessage`}</inlineCode>{` delegate method is called on a background thread. Any direct interactions with the Message's `}<inlineCode parentName="p">{`WKWebView`}</inlineCode>{` made by the delegate should be dispatched back to the main thread.`}</p>
    <p>{`In the example below, you can decide whether or not the in-app message should be directly integrated into your existing UI. If so, you capture a reference to the message's `}<inlineCode parentName="p">{`WKWebView`}</inlineCode>{` and return `}<inlineCode parentName="p">{`false`}</inlineCode>{` to prevent the message from being shown by the SDK:`}</p>
    <h4 {...{
      "id": "swift-4"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var inAppMessageView: WKWebView?

func shouldShowMessage(message: Showable) -> Bool {    
    if shouldIntegrateMessageDirectly {
        let fullscreenMessage = message as? FullscreenMessage
        let message = fullscreenMessage?.parent

        inAppMessageView = message?.view as? WKWebView

        return false
    }

    return true
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      