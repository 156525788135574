import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This example uses `}<inlineCode parentName="p">{`static`}</inlineCode>{` constant strings that were provided in the `}<inlineCode parentName="p">{`trackBeacon`}</inlineCode>{` code sample above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void clearCurrentBeacon() {
 UserProfile.removeUserAttributes(Arrays.asList(BEACON_MAJOR, BEACON_MINOR, BEACON_UUID, BEACON_PROXIMITY));
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      