import TrackBeaconAndroid from "../../../../src/pages/solution/adobe-analytics/tabs/track-beacon/track-beacon/android.md";
import TrackBeaconIos from "../../../../src/pages/solution/adobe-analytics/tabs/track-beacon/track-beacon/ios.md";
import ClearBeaconAndroid from "../../../../src/pages/solution/adobe-analytics/tabs/track-beacon/clear-beacon/android.md";
import ClearBeaconIos from "../../../../src/pages/solution/adobe-analytics/tabs/track-beacon/clear-beacon/ios.md";
import * as React from 'react';
export default {
  TrackBeaconAndroid,
  TrackBeaconIos,
  ClearBeaconAndroid,
  ClearBeaconIos,
  React
};