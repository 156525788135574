import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p>{`Download the iOS Swift Sample application from the `}<a parentName="p" {...{
        "href": "https://github.com/adobe/aepsdk-sample-app-ios/archive/beta-assignment-3.zip"
      }}>{`Adobe Experience Platform SDK GitHub`}</a>{`.`}</p>
    <p>{`To get started, follow the steps described in the installation section of the `}<a parentName="p" {...{
        "href": "https://github.com/adobe/aepsdk-sample-app-ios/tree/beta-assignment-3#installation"
      }}>{`Adobe Experience Platform SDK GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      