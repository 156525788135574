import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const InlineNestedAlert = makeShortcode("InlineNestedAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="get" repeat="1" mdxType="Variant" />
    <p>{`Latest version of the Adobe Experience Platform SDKs for Android supports Android 5.0 (API 21) or later.`}</p>
    <Variant platform="ios" task="get" repeat="2" mdxType="Variant" />
    <p>{`Adobe Experience Platform SDKs for iOS support `}<strong parentName="p">{`iOS 12 or later`}</strong>{`; `}<strong parentName="p">{`requires`}</strong>{` Swift 5.1 or newer; `}<strong parentName="p">{`and`}</strong>{` Xcode 15.0 or newer.`}</p>
    <p>{`In order to support the new Apple M1 architecture while maintaining support for existing Intel architecture, the Adobe Experience Platform SDKs are now distributed using XCFrameworks. Please see the `}<a parentName="p" {...{
        "href": "../current-sdk-versions.md"
      }}>{`current SDK versions`}</a>{` for more information on the latest extension versions.`}</p>
    {/* <Variant platform="react-native" task="get" repeat="7"/>
     #### React Native
     Adobe Experience Platform Mobile SDK plugin for React Native supports React Native **version 0.60.0 or later**. For the latest installation instructions, see the `README` file in the [`react-native-acpcore`](https://github.com/adobe/react-native-acpcore) repository.
     For React Native, you should install [Node.js](https://nodejs.org) to download packages from [npm](https://npmjs.com). For additional instructions, see this [tutorial on getting started with React Native applications](https://facebook.github.io/react-native/docs/getting-started).
     v2.0.0 and above of the AEP Mobile SDK React Native plugins use [autolinking](https://github.com/react-native-community/cli/blob/master/docs/autolinking.md), which links plugins' native dependencies automatically. For iOS development, after installing the plugins from npm, download the pod dependencies by running the following command:
     `cd ios && pod install && cd ..`
     To update native dependencies to latest available versions, run the following command:
     `cd ios && pod update && cd ..` */}
    {/* <Variant platform="flutter" task="get" repeat="3"/>
     #### Flutter
     Adobe Experience Platform Mobile SDK plugin for Flutter supports Flutter **versions 1.10.0 or later**.
     For the latest Flutter installation instructions, see the package [install tab](https://pub.dev/packages/flutter_acpcore#-installing-tab-). */}
    <Variant platform="android" task="add-dependencies" repeat="3" mdxType="Variant" />
    <p>{`Add the dependencies to `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{` for each extension.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`implementation platform('com.adobe.marketing.mobile:sdk-bom:3.+')
implementation 'com.adobe.marketing.mobile:userprofile'
implementation 'com.adobe.marketing.mobile:core'
implementation 'com.adobe.marketing.mobile:identity'
implementation 'com.adobe.marketing.mobile:signal'
implementation 'com.adobe.marketing.mobile:lifecycle'
`}</code></pre>
    <InlineNestedAlert variant="warning" header="false" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`Using dynamic dependency versions is `}<strong parentName="p">{`not`}</strong>{` recommended for production apps. Please read the `}<a parentName="p" {...{
          "href": "../../resources/manage-gradle-dependencies.md"
        }}>{`managing Gradle dependencies guide`}</a>{` for more information.`}</p>
    </InlineNestedAlert>
    <Variant platform="ios" task="add-dependencies" repeat="8" mdxType="Variant" />
    <p>{`Create a `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` if you do not already have one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-pod"
      }}>{`pod init
`}</code></pre>
    <p>{`Add the dependencies to your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` for each extension.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-pod"
      }}>{`use_frameworks!
pod 'AEPEdgeConsent', '~> 5.0'
pod 'AEPAssurance', '~> 5.0'
pod 'AEPEdgeIdentity', '~> 5.0'
pod 'AEPEdge', '~> 5.0'
pod 'AEPUserProfile', '~> 5.0'
pod 'AEPCore', '~> 5.0'
pod 'AEPIdentity', '~> 5.0'
pod 'AEPSignal', '~> 5.0'
pod 'AEPLifecycle', '~> 5.0'
`}</code></pre>
    <p>{`If Cocoapods cannot not find the dependencies, you may need to run this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-pod"
      }}>{`pod repo update
`}</code></pre>
    <p>{`Save the `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` and run install:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-pod"
      }}>{`pod install
`}</code></pre>
    <Variant platform="android" task="add-initialization" repeat="5" mdxType="Variant" />
    <p>{`After you register the extensions, call the `}<inlineCode parentName="p">{`start`}</inlineCode>{` API in Mobile Core to initialize the SDK as shown below. This step is required to boot up the SDK for event processing. The following code snippet is provided as a sample reference.`}</p>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.AdobeCallback;
import com.adobe.marketing.mobile.Assurance;
import com.adobe.marketing.mobile.Edge;
import com.adobe.marketing.mobile.Extension;
import com.adobe.marketing.mobile.Identity;
import com.adobe.marketing.mobile.Lifecycle;
import com.adobe.marketing.mobile.LoggingMode;
import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Signal;
import com.adobe.marketing.mobile.UserProfile;
import com.adobe.marketing.mobile.edge.consent.Consent;
import com.adobe.marketing.mobile.edge.identity.Identity;
import java.util.Arrays;
import java.util.List;
...
import android.app.Application;
...
public class MainApp extends Application {
  ...
  @Override
  public void on Create(){
    super.onCreate();
    MobileCore.setApplication(this);
    MobileCore.setLogLevel(LoggingMode.DEBUG);
    ...
    List<Class<? extends Extension>> extensions = Arrays.asList(
      Consent.EXTENSION,
      Assurance.EXTENSION,
      com.adobe.marketing.mobile.edge.identity.Identity.EXTENSION,
      com.adobe.marketing.mobile.Identity.EXTENSION,
      Edge.EXTENSION,
      UserProfile.EXTENSION,
      Lifecycle.EXTENSION,
      Signal.EXTENSION
    );

    MobileCore.registerExtensions(extensions, new AdobeCallback () {
        @Override
        public void call(Object o) {
            MobileCore.configureWithAppID("<your_environment_file_id>");
        }
    });
  }
}
`}</code></pre>
    <h4 {...{
      "id": "java-direct-boot-enabled"
    }}>{`Java (Direct Boot enabled)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.AdobeCallback;
import com.adobe.marketing.mobile.Assurance;
import com.adobe.marketing.mobile.Edge;
import com.adobe.marketing.mobile.Extension;
import com.adobe.marketing.mobile.Identity;
import com.adobe.marketing.mobile.Lifecycle;
import com.adobe.marketing.mobile.LoggingMode;
import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Signal;
import com.adobe.marketing.mobile.UserProfile;
import com.adobe.marketing.mobile.edge.consent.Consent;
import com.adobe.marketing.mobile.edge.identity.Identity;
import java.util.Arrays;
import java.util.List;
...
import android.app.Application;
import androidx.core.os.UserManagerCompat; // Access features in android UserManager in backwards compatible manner 
...

public class MainApp extends Application {
  ...
  @Override
  public void on Create(){
    super.onCreate();
    if(UserManagerCompat.isUserUnlocked(this.getApplicationContext())) {
      MobileCore.setApplication(this);
      MobileCore.setLogLevel(LoggingMode.DEBUG);
      ...
      List<Class<? extends Extension>> extensions = Arrays.asList(
        Consent.EXTENSION,
        Assurance.EXTENSION,
        com.adobe.marketing.mobile.edge.identity.Identity.EXTENSION,
        com.adobe.marketing.mobile.Identity.EXTENSION,
        Edge.EXTENSION,
        UserProfile.EXTENSION,
        Lifecycle.EXTENSION,
        Signal.EXTENSION
      );

      MobileCore.registerExtensions(extensions, new AdobeCallback () {
          @Override
          public void call(Object o) {
              MobileCore.configureWithAppID("<your_environment_file_id>");
          }
      });
    } 
  }
}
`}</code></pre>
    <Variant platform="ios" task="add-initialization" repeat="5" mdxType="Variant" />
    <p>{`For iOS Swift libraries, registration is changed to a single API call (as shown in the snippets below). Calling the`}<inlineCode parentName="p">{`MobileCore.start`}</inlineCode>{` API is no longer required.`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AppDelegate.swift
import AEPCore
import AEPEdgeConsent
import AEPAssurance
import AEPEdgeIdentity
import AEPEdge
import AEPUserProfile
import AEPIdentity
import AEPLifecycle
import AEPSignal
import AEPServices


final class AppDelegate: NSObject, UIApplicationDelegate {
  func application(_: UIApplication, didFinishLaunchingWithOptions _: [UIApplication.LaunchOptionsKey: Any]? = nil) -> Bool {
    MobileCore.setLogLevel(.debug)
    let appState = application.applicationState
    ...
    let extensions = [
                  Consent.self,
                  Assurance.self,
                  AEPEdgeIdentity.Identity.self,
                  AEPIdentity.Identity.self,
                  Edge.self,
                  UserProfile.self,
                  Lifecycle.self,
                  Signal.self
                ]
    MobileCore.registerExtensions(extensions, {
        MobileCore.configureWith(appId: "<your_environment_file_id>")
        if appState != .background {
            MobileCore.lifecycleStart(additionalContextData: ["contextDataKey": "contextDataVal"])
        }
    })
    ...
  }
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.m
#import "AppDelegate.h"
@import AEPCore;
@import AEPEdgeConsent;
@import AEPAssurance;
@import AEPEdgeIdentity;
@import AEPEdge;
@import AEPUserProfile;
@import AEPIdentity;
@import AEPLifecycle;
@import AEPSignal;
@import AEPServices;
...
@implementation AppDelegate
- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  [AEPMobileCore setLogLevel: AEPLogLevelDebug];
  ...
  NSArray *extensionsToRegister = @[
                                AEPMobileEdgeConsent.class,
                                AEPMobileAssurance.class,
                                AEPMobileEdgeIdentity.class,
                                AEPMobileEdge.class,
                                AEPMobileUserProfile.class,
                                AEPMobileIdentity.class,
                                AEPMobileLifecycle.class,
                                AEPMobileSignal.class
                              ];
  [AEPMobileCore registerExtensions:extensionsToRegister completion:^{
      [AEPMobileCore lifecycleStart:@{@"contextDataKey": @"contextDataVal"}];
  }];
  [AEPMobileCore configureWithAppId: @"<your_environment_file_id>"];
  ...
  return YES;
}
@end
`}</code></pre>
    <Variant platform="react-native" task="add-initialization" repeat="5" mdxType="Variant" />
    <p>{`For React Native apps, initialize the SDK using native code in your `}<inlineCode parentName="p">{`AppDelegate`}</inlineCode>{` (iOS) and `}<inlineCode parentName="p">{`MainApplication`}</inlineCode>{` (Android).`}</p>
    <h4 {...{
      "id": "ios"
    }}>{`iOS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`#import "ACPCore.h"
#import "ACPUserProfile.h"
#import "ACPIdentity.h"
#import "ACPLifecycle.h"
#import "ACPSignal.h"
...
@implementation AppDelegate
-(BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    [ACPCore setLogLevel:ACPMobileLogLevelDebug];
    [ACPCore configureWithAppId:@"<your_environment_file_id>"];
    [ACPUserProfile registerExtension];
    [ACPIdentity registerExtension];
    [ACPLifecycle registerExtension];
    [ACPSignal registerExtension];

    const UIApplicationState appState = application.applicationState;
    [ACPCore start:^{
      // only start lifecycle if the application is not in the background
      if (appState != UIApplicationStateBackground) {
        [ACPCore lifecycleStart:nil];
      }
    }];
    ...
  return YES;
}

@end
`}</code></pre>
    <h4 {...{
      "id": "android"
    }}>{`Android`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.AdobeCallback;
import com.adobe.marketing.mobile.Identity;
import com.adobe.marketing.mobile.InvalidInitException;
import com.adobe.marketing.mobile.Lifecycle;
import com.adobe.marketing.mobile.LoggingMode;
import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Signal;
import com.adobe.marketing.mobile.UserProfile;
...
import android.app.Application;
...
public class MainApplication extends Application implements ReactApplication {
  ...
  @Override
  public void on Create(){
    super.onCreate();
    ...
    MobileCore.setApplication(this);
    MobileCore.setLogLevel(LoggingMode.DEBUG);
    MobileCore.setWrapperType(WrapperType.REACT_NATIVE);

    try {
      UserProfile.registerExtension();
      Identity.registerExtension();
      Lifecycle.registerExtension();
      Signal.registerExtension();
      MobileCore.start(new AdobeCallback () {
          @Override
          public void call(Object o) {
            MobileCore.configureWithAppID("<your_environment_file_id>");
         }
      });
    } catch (InvalidInitException e) {
      ...
    }
  }
}
`}</code></pre>
    <Variant platform="flutter" task="add-initialization" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "dart"
    }}>{`Dart`}</h4>
    <p>{`For Flutter apps, initialize the SDK using native code in your `}<inlineCode parentName="p">{`AppDelegate`}</inlineCode>{` and `}<inlineCode parentName="p">{`MainApplication`}</inlineCode>{` in iOS and Android, respectively.`}</p>
    <p>{`The initialization code is located in the `}<a parentName="p" {...{
        "href": "https://github.com/adobe/flutter_acpcore"
      }}>{`Flutter ACPCore Github README`}</a>{`.`}</p>
    <Variant platform="cordova" task="add-initialization" repeat="5" mdxType="Variant" />
    <p>{`For Cordova apps, initialize the SDK using native code in your `}<inlineCode parentName="p">{`AppDelegate`}</inlineCode>{` and `}<inlineCode parentName="p">{`MainApplication`}</inlineCode>{` in iOS and Android, respectively.`}</p>
    <p><strong parentName="p">{`iOS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`// Import the SDK
#import "ACPCore.h"
#import "ACPLifecycle.h"
#import "ACPIdentity.h"
#import "ACPSignal.h"
- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {  
  // make sure to set the wrapper type at the beginning of initialization
  [ACPCore setWrapperType:ACPMobileWrapperTypeCordova];

  //...
  [ACPCore configureWithAppId:@"<your_environment_file_id>"];
  [ACPIdentity registerExtension];
  [ACPLifecycle registerExtension];
  [ACPSignal registerExtension];
  // Register any additional extensions

  [ACPCore start:nil];
}
`}</code></pre>
    <p><strong parentName="p">{`Android`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Import the SDK
import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Identity;
import com.adobe.marketing.mobile.Lifecycle;
import com.adobe.marketing.mobile.Signal;
import com.adobe.marketing.mobile.WrapperType;

@Override
public void onCreate() {
  //...
  MobileCore.setApplication(this);
  MobileCore.configureWithAppID("<your_environment_file_id>");

  // make sure to set the wrapper type at the beginning of initialization
  MobileCore.setWrapperType(WrapperType.CORDOVA);

  try {
    Identity.registerExtension();
    Lifecycle.registerExtension();
    Signal.registerExtension();

    // Register any additional extensions
  } catch (Exception e) {
    // handle exception
  }

  MobileCore.start(null);
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      