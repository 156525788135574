import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="import" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p>{`Import the Lifecycle library:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.*;
`}</code></pre>
    <p>{`Register the Lifecycle extension along with other extensions used with Mobile Core:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyApp extends Application {​

    @Override
    public void onCreate() {
        super.onCreate();
        MobileCore.setApplication(this);

        MobileCore.registerExtensions(Arrays.asList(
      Lifecycle.EXTENSION,
            ...
        ), value -> {
        // registration completion handler
        });
    }
}
`}</code></pre>
    <Variant platform="ios" task="import" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPCore
import AEPLifecycle
`}</code></pre>
    <p>{`Register the Lifecycle extension along with the other extensions you use with the Mobile Core by adding the following in your app's `}<inlineCode parentName="p">{`application(_:didFinishLaunchingWithOptions:)`}</inlineCode>{` delegate method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
  MobileCore.registerExtensions([Lifecycle.self, ...], {
      // Use the environment file id assigned to this application in Data Collection UI
      MobileCore.configureWith(appId: "your-environment-file-id")
  })

  return true
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`@import AEPCore;
@import AEPLifecycle;
`}</code></pre>
    <p>{`Register the Lifecycle extension along with the other extensions you use with the Mobile Core by adding the following to your app's `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL) application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  [AEPMobileCore registerExtensions:@[AEPMobileLifecycle.class, ...] completion:^{
      // Use the environment file id assigned to this application in Data Collection UI
      [AEPMobileCore configureWithAppId: @"your-environment-file-id"];
  }];

  return YES;
}
`}</code></pre>
    <Variant platform="android" task="collect" repeat="6" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p>{`Do not start or stop Lifecycle in a Fragment.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`onResume`}</inlineCode>{` function of each of your Activities, start Lifecycle data collection:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override  
public void onResume() {  
    MobileCore.setApplication(getApplication());
    MobileCore.lifecycleStart(null);
}
`}</code></pre>
    <p>{`To ensure accurate session and crash reporting, this call must be added to every Activity.`}</p>
    <p>{`Setting the application is only necessary on activities that are entry points for your application. However, setting the application on each Activity has no negative impact and ensures that the SDK always has the necessary reference to your application. We recommend that you call `}<inlineCode parentName="p">{`setApplication`}</inlineCode>{` in each of your Activities.`}</p>
    <Variant platform="ios" task="collect" repeat="16" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p>{`Start Lifecycle data collection by calling `}<inlineCode parentName="p">{`lifecycleStart(additionalContextData:)`}</inlineCode>{` from the registration completion handler in your app's `}<inlineCode parentName="p">{`application(_:didFinishLaunchingWithOptions:)`}</inlineCode>{` delegate method.`}</p>
    <p>{`If your iOS application supports background capabilities, your `}<inlineCode parentName="p">{`application(_:didFinishLaunchingWithOptions:)`}</inlineCode>{` method might be called when iOS launches your app in the background. If you do not want background launches to count towards your lifecycle metrics, `}<inlineCode parentName="p">{`lifecycleStart(additionalContextData:)`}</inlineCode>{` should only be called when the application state is not equal to `}<inlineCode parentName="p">{`UIApplication.State.background`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplicationLaunchOptionsKey: Any]?) -> Bool {
  let appState = application.applicationState
  MobileCore.registerExtensions([Lifecycle.self, ...], {
      // Use the environment file id assigned to this application in Data Collection UI
      MobileCore.configureWith(appId: "your-environment-file-id")

      // only start lifecycle if the application is not in the background
      if appState != .background {
          MobileCore.lifecycleStart(additionalContextData: nil)
      }
  })
}
`}</code></pre>
    <p>{`When your app is launched, if it is resuming from a backgrounded state, iOS might call your `}<inlineCode parentName="p">{`applicationWillEnterForeground:`}</inlineCode>{` delegate method. You also need to call `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{`, but this time you do not need all of the supporting code that you used in `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func applicationWillEnterForeground(_ application: UIApplication) {
    MobileCore.lifecycleStart(additionalContextData: nil)
}
`}</code></pre>
    <p>{`If your app is a SceneDelegate based iOS application, then use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func sceneWillEnterForeground(_ scene: UIScene) {
    MobileCore.lifecycleStart(additionalContextData: nil)
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p>{`Start Lifecycle data collection by calling `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{` from within the callback of the `}<inlineCode parentName="p">{`ACPCore::start:`}</inlineCode>{` method in your app's `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method.`}</p>
    <p>{`If your iOS application supports background capabilities, your `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` method might be called when iOS launches your app in the background. If you do not want background launches to count towards your lifecycle metrics, `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{` should only be called when the application state is not equal to `}<inlineCode parentName="p">{`UIApplicationStateBackground`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL) application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {

    const UIApplicationState appState = application.applicationState;
    [AEPMobileCore registerExtensions:@[AEPMobileLifecycle.class, ...] completion:^{
        // Use the environment file id assigned to this application in Data Collection UI
        [AEPMobileCore configureWithAppId: @"your-environment-file-id"];
        // only start lifecycle if the application is not in the background
        if (appState != UIApplicationStateBackground) {
            [AEPMobileCore lifecycleStart:nil];
        }
    }];

    return YES;
}
`}</code></pre>
    <p>{`When your app is launched, if it is resuming from a backgrounded state, iOS might call your `}<inlineCode parentName="p">{`applicationWillEnterForeground:`}</inlineCode>{` delegate method. You also need to call `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{`, but this time you do not need all of the supporting code that you used in `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) applicationWillEnterForeground:(UIApplication *)application {
    [AEPMobileCore lifecycleStart:nil];
}
`}</code></pre>
    <p>{`If your app is a SceneDelegate based iOS application, then use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) sceneWillEnterForeground:(UIScene *)scene {
    [AEPMobileCore lifecycleStart:nil];
}
`}</code></pre>
    <Variant platform="android" task="pause" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p>{`Do not start or stop Lifecycle in a Fragment.`}</p>
    <p>{`We recommend pausing Lifecycle from the `}<inlineCode parentName="p">{`onPause`}</inlineCode>{` function in your Activities:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
public void onPause() {
    MobileCore.lifecyclePause();
}
`}</code></pre>
    <p>{`To ensure accurate session and crash reporting, this call must be added to every Activity.`}</p>
    <Variant platform="ios" task="pause" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <p>{`When the app enters the background, pause Lifecycle data collection from your app's `}<inlineCode parentName="p">{`applicationDidEnterBackground:`}</inlineCode>{` delegate method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func applicationDidEnterBackground(_ application: UIApplication) {
    MobileCore.lifecyclePause()
}
`}</code></pre>
    <p>{`If your app is a SceneDelegate based iOS application, then use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func sceneDidEnterBackground(_ scene: UIScene) {
    MobileCore.lifecyclePause()
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c-2"
    }}>{`Objective-C`}</h4>
    <p>{`When the app enters the background, pause Lifecycle data collection from your app's `}<inlineCode parentName="p">{`applicationDidEnterBackground:`}</inlineCode>{` delegate method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) applicationDidEnterBackground:(UIApplication *)application {
   [AEPMobileCore lifecyclePause];
}
`}</code></pre>
    <p>{`If your app is a SceneDelegate based iOS application, then use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) sceneDidEnterBackground:(UIScene *)scene {
    [AEPMobileCore lifecyclePause];
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      