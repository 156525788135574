import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="storage-locations" repeat="2" mdxType="Variant" />
    <p>{`The SDK uses the cache and shared preferences at these locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`data/data/your.app.package/cache
data/data/your.app.package/shared_prefs
`}</code></pre>
    <Variant platform="ios" task="storage-locations" repeat="3" mdxType="Variant" />
    <p>{`On iOS, the SDK uses the cache and local storage at these locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Library/Caches/com.adobe.*
Library/com.adobe.aep.datastore
`}</code></pre>
    <p>{`On tvOS, the SDK uses `}<inlineCode parentName="p">{`NSUserDefaults`}</inlineCode>{` using the prefix `}<inlineCode parentName="p">{`adobe.*`}</inlineCode>{`.`}</p>
    <Variant platform="ios" task="configuration-download-error" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`[AEP SDK ERROR - <ConfigurationDownloader>] Loading config from URL https://assets.adobedtm.com/<APP_ID>.json failed with response code: Optional(404)
`}</code></pre>
    <Variant platform="android" task="configuration-download-error" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`D/AdobeExperienceSDK: HttpConnectionHandler - Connecting to URL https://assets.adobedtm.com/<APP_ID>.json (GET)
W/AdobeExperienceSDK: RemoteDownloader - File could not be downloaded from URL (https://assets.adobedtm.com/<APP_ID>.json) Response: (-1) Message: (null)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      