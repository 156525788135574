import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" function="enum" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public enum PushTrackingStatus {
    TRACKING_INITIATED,
    NO_DATASET_CONFIGURED,
    NO_TRACKING_DATA,
    INVALID_INTENT,
    INVALID_MESSAGE_ID,
    UNKNOWN_ERROR;
}
`}</code></pre>
    <Variant platform="ios" function="enum" repeat="1" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(AEPPushTrackingStatus)
public enum PushTrackingStatus: Int {
    case trackingInitiated
    case noDatasetConfigured
    case noTrackingData
    case invalidMessageId
    case unknownError
}
`}</code></pre>
    <Variant platform="android" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Enum`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRACKING_INITIATED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when all the required data for tracking is available and tracking is initiated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NO_DATASET_CONFIGURED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because no tracking dataset is configured.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NO_TRACKING_DATA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because the intent does not contain tracking data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INVALID_INTENT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because the intent is invalid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INVALID_MESSAGE_ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because the message ID is invalid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UNKNOWN_ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because of an unknown error.`}</td>
        </tr>
      </tbody>
    </table>
    <Variant platform="ios" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Enum`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trackingInitiated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when all the required data for tracking is available and tracking is initiated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noDatasetConfigured`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because no tracking dataset is configured.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noTrackingData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because the intent does not contain tracking data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invalidMessageId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because the message ID is invalid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unknownError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This status is returned when tracking is not initiated because of an unknown error.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      