import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const InlineNestedAlert = makeShortcode("InlineNestedAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" function="enum" repeat="3" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="true" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This enum has been changed in v3.0.0 of Adobe Journey Optimizer extension.`}</p>
      <p>{`Please use newly added `}<inlineCode parentName="p">{`DISMISS`}</inlineCode>{`, `}<inlineCode parentName="p">{`INTERACT`}</inlineCode>{`, `}<inlineCode parentName="p">{`TRIGGER`}</inlineCode>{`, `}<inlineCode parentName="p">{`DISPLAY`}</inlineCode>{` values in place of `}<inlineCode parentName="p">{`IN_APP_DISMISS`}</inlineCode>{`, `}<inlineCode parentName="p">{`IN_APP_INTERACT`}</inlineCode>{`, `}<inlineCode parentName="p">{`IN_APP_TRIGGER`}</inlineCode>{`, `}<inlineCode parentName="p">{`IN_APP_DISPLAY`}</inlineCode>{` from earlier versions.`}</p>
    </InlineNestedAlert>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public enum MessagingEdgeEventType {
    PUSH_APPLICATION_OPENED(4),
    PUSH_CUSTOM_ACTION(5),
    DISMISS(6),
    INTERACT(7),
    TRIGGER(8),
    DISPLAY(9),
    DISQUALIFY(10),
    SUPPRESS_DISPLAY(11);

    MessagingEdgeEventType(final int value) {
        this.value = value;
    }

    /**
     * @deprecated This method will be removed in future versions.
     */
    @Deprecated
    public int getValue() {
        return value;
    }

    public String getPropositionEventType() {
        switch (this) {
            case DISMISS:
                return PROPOSITION_EVENT_TYPE_DISMISS;
            case INTERACT:
                return PROPOSITION_EVENT_TYPE_INTERACT;
            case TRIGGER:
                return PROPOSITION_EVENT_TYPE_TRIGGER;
            case DISPLAY:
                return PROPOSITION_EVENT_TYPE_DISPLAY;
            case DISQUALIFY:
                return PROPOSITION_EVENT_TYPE_DISQUALIFY;
            case SUPPRESS_DISPLAY:
                return PROPOSITION_EVENT_TYPE_SUPPRESS_DISPLAY;
            default:
                return "";
        }
    }

    @NonNull @Override
    public String toString() {
        switch (this) {
            case DISMISS:
                return PROPOSITION_EVENT_TYPE_DISMISS_STRING;
            case INTERACT:
                return PROPOSITION_EVENT_TYPE_INTERACT_STRING;
            case TRIGGER:
                return PROPOSITION_EVENT_TYPE_TRIGGER_STRING;
            case DISPLAY:
                return PROPOSITION_EVENT_TYPE_DISPLAY_STRING;
            case DISQUALIFY:
                return PROPOSITION_EVENT_TYPE_DISQUALIFY_STRING;
            case SUPPRESS_DISPLAY:
                return PROPOSITION_EVENT_TYPE_SUPPRESS_DISPLAY_STRING;
            case PUSH_APPLICATION_OPENED:
                return PUSH_NOTIFICATION_EVENT_TYPE_STRING_OPENED;
            case PUSH_CUSTOM_ACTION:
                return PUSH_NOTIFICATION_EVENT_TYPE_STRING_CUSTOM_ACTION;
            default:
                return super.toString();
        }
    }
}
`}</code></pre>
    <Variant platform="ios" function="enum" repeat="3" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="true" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This enum has been changed in v5.0.0 of Adobe Journey Optimizer extension.`}</p>
      <p>{`Please use newly added  values `}<inlineCode parentName="p">{`dismiss`}</inlineCode>{`, `}<inlineCode parentName="p">{`interact`}</inlineCode>{`, `}<inlineCode parentName="p">{`trigger`}</inlineCode>{`, `}<inlineCode parentName="p">{`display`}</inlineCode>{` in place of `}<inlineCode parentName="p">{`inappDismiss`}</inlineCode>{`, `}<inlineCode parentName="p">{`inappInteract`}</inlineCode>{`, `}<inlineCode parentName="p">{`inappTrigger`}</inlineCode>{`, `}<inlineCode parentName="p">{`inappDisplay`}</inlineCode>{` from earlier versions.`}</p>
    </InlineNestedAlert>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(AEPMessagingEdgeEventType)
public enum MessagingEdgeEventType: Int {
    case pushApplicationOpened = 4
    case pushCustomAction = 5
    case dismiss = 6
    case interact = 7
    case trigger = 8
    case display = 9
    case disqualify = 10
    case suppressDisplay = 11

    public func toString() -> String {
        switch self {
        case .dismiss:
            return MessagingConstants.XDM.Inbound.EventType.DISMISS
        case .trigger:
            return MessagingConstants.XDM.Inbound.EventType.TRIGGER
        case .interact:
            return MessagingConstants.XDM.Inbound.EventType.INTERACT
        case .display:
            return MessagingConstants.XDM.Inbound.EventType.DISPLAY
        case .disqualify:
            return MessagingConstants.XDM.Inbound.EventType.DISQUALIFY
        case .suppressDisplay:
            return MessagingConstants.XDM.Inbound.EventType.SUPPRESSED_DISPLAY
        case .pushCustomAction:
            return MessagingConstants.XDM.Push.EventType.CUSTOM_ACTION
        case .pushApplicationOpened:
            return MessagingConstants.XDM.Push.EventType.APPLICATION_OPENED
        }
    }
}
`}</code></pre>
    <Variant platform="android" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`String value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DISMISS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDismiss`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INTERACT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionInteract`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRIGGER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionTrigger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DISPLAY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DISQUALIFY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisqualify`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SUPPRESS_DISPLAY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionSuppressDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PUSH_APPLICATION_OPENED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.applicationOpened`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PUSH_CUSTOM_ACTION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.customAction`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <Variant platform="ios" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`String value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDismiss`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionInteract`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trigger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionTrigger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`display`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disqualify`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisqualify`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`suppressDisplay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionSuppressDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushApplicationOpened`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.applicationOpened`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushCustomAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.customAction`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      