import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="fcm" template="catalog" repeat="18" mdxType="Variant" />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`The properties below define the payload sent to FCM:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payload Version`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Version of the payload assigned by the ACC authoring UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Template Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Informs the reader which properties may exist in the template object.`}<br />{`Product catalog template uses a value of "cat".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in the notification's title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_title`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in message body when notification is collapsed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expanded Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_ex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body of the message when the message is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_body`}</inlineCode>{`, `}<inlineCode parentName="td">{`adb_body_ex`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound played when notification is delivered.`}<br />{`Value should be the name of the sound file without its extension.`}<br />{`Sound file should be located in the `}<inlineCode parentName="td">{`assets/raw`}</inlineCode>{` directory of your app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link URI`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when user clicks the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Type of link represented in `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` - one of "WEBURL", "DEEPLINK", "DISMISS", or "OPENAPP".`}<br />{`Required if `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` is specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a small icon to use in the notification. `}<br /><strong parentName="td">{`Note`}</strong>{` - The value referenced by this key is not used if a valid `}<inlineCode parentName="td">{`adb_small_icon`}</inlineCode>{` key value pair is present in the payload.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_small_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a small icon to use in the notification. The app's drawable resources are checked for an image file with the provided name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the notification's small icon.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Large Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_large_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI or name for a large icon to use in the notification. If the URI is valid, the remote image is downloaded and cached on the device to be used as the large icon. If a name is provided, the app's drawable resources are checked for an image file with the same name. If no valid URI or name is provided then no large icon is set on the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Channel ID`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The `}<a parentName="td" {...{
              "href": "https://developer.android.com/guide/topics/ui/notifiers/notifications#ManageChannels"
            }}>{`notification's channel id`}</a>{` (new in Android O). The app must create a channel with this channel ID before any notification with this channel ID is received. If you don't send this channel ID in the request, or if the channel ID provided has not yet been created by the app, FCM uses the channel ID specified in the app manifest.`}<br />{`If not provided in payload, SDK uses a "default" channel ID of value "General Notifications".`}<br />{`If < API 26 (Android O), this value is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Badge Count`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value to be used on app's badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Priority`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Designates the notification priority for Android - `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/android/app/NotificationChannel#setImportance(int)"
            }}>{`importance`}</a>{` for API >= 26 (Android 8.0) or `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/androidx/core/app/NotificationCompat.Builder#setPriority(int)"
            }}>{`priority`}</a>{` for API < 26.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Identifier used to replace existing notifications in the notification drawer. If not specified, each request creates a new notification. If specified and a notification with the same tag is already being shown, the new notification replaces the existing one in the notification drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sticky`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sticky`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean value represented as a string "true" or "false".`}<br />{`When set to false or unset, the notification is automatically dismissed when the user clicks it in the panel. When set to true, the notification persists even when the user clicks it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ticker`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_ticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the "ticker" text, which is sent to accessibility services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for notification's background.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Call to action button text`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_cta_txt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text to be shown on the CTA button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Call to action button link`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_cta_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when the user clicks the CTA button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Call to action button color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_cta_clr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the CTA button.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Call to action button text color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_cta_txt_clr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the CTA button text. `}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Display type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_display`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Determines the layout of the catalog notification.`}<br />{`Value will either be "horizontal" (left-to-right) or "vertical" (top-to-bottom).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Catalog Items`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Three entries describing the items in the product catalog.`}<br /><strong parentName="td">{`The value is an encoded JSON string.`}</strong><br />{`When decoded, the string contains an array of the following objects:`}<br /><ul><li><inlineCode parentName="td">{`title`}</inlineCode>{` (required) - Text to use in the title if this product is selected.`}</li><li><inlineCode parentName="td">{`body`}</inlineCode>{` (required) - Text to use in the body if this product is selected.`}</li><li><inlineCode parentName="td">{`img`}</inlineCode>{` (required) - URI to an image to use in notification when this product is selected.`}</li><li><inlineCode parentName="td">{`price`}</inlineCode>{` (required) - Price of this product to display when the notification is selected.`}</li><li><inlineCode parentName="td">{`uri`}</inlineCode>{` (required) - URI to be handled when the user clicks the large image of the selected item.`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Vertical Product Catalog:`}</p>
    <img {...{
      "src": "/client-sdks/assets/7d148e26e83d945a2d2fcabc8282d85a/vertical_catalog.gif",
      "width": "50%",
      "height": "50%"
    }}></img>
    <p>{`Horizontal Product Catalog:`}</p>
    <img {...{
      "src": "/client-sdks/assets/2a1cecda9d6adc1a1fb25131f8febfd3/horizontal_catalog.gif",
      "width": "50%",
      "height": "50%"
    }}></img>
    <p>{`Below is a sample of what a payload might look like for a product catalog notification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message": {
    "token": "FCM_TOKEN",
    "android": {
      "collapse_key": "new message"
    },    
    "data": {
      "adb_version": "1",
      "adb_title": "Buy some shoes",
      "adb_body": "Click a shoe to learn more",
      "adb_sound": "squeek",
      "adb_uri": "https://sneakerland.com/products/shoe1",
      "adb_a_type": "WEBURL",
      "adb_icon": "ic_shoe",
      "adb_n_count": "1",
      "adb_n_priority": "PRIORITY_LOW",
      "adb_template_type": "cat",
      "adb_clr_body": "00EE00",
      "adb_clr_title": "AABBCC",
      "adb_clr_icon": "123456",
      "adb_clr_bg": "000000",
      "adb_display": "vertical",
      "adb_cta_txt": "Buy Now",
      "adb_cta_txt_clr": "00EE00",
      "adb_cta_uri": "https://sneakerland.com/cart&addItem=shoe1",
      "adb_items": "[{\\"title\\":\\"Cool Shoe\\",\\"body\\":\\"Shoe 1 by Cool Sneaker Brand\\",\\"img\\":\\"https://sneakerland.com/products/shoe1/shoe1.png\\",\\"price\\":\\"49.97\\",\\"uri\\":\\"https://sneakerland.com/products/shoe1\\"},{\\"title\\":\\"Lame Shoe\\",\\"body\\":\\"Shoe 2 by Lame Sneaker Brand\\",\\"img\\":\\"https://sneakerland.com/products/shoe2/shoe2.png\\",\\"price\\":\\"99.99\\",\\"uri\\":\\"https://sneakerland.com/products/shoe2\\"}]"
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "usage-recommendations"
    }}>{`Usage recommendations`}</h2>
    <p>{`The tables below contain guidelines for your push notification content. `}<strong parentName="p">{`These recommendations help your text and images display reliably across multiple devices.`}</strong>{` These values are guidelines only - you should still test a notification prior to sending it.`}</p>
    <h3 {...{
      "id": "audio-specifications"
    }}>{`Audio specifications`}</h3>
    <p>{`Custom sound files must be placed within the app's bundle in the `}<inlineCode parentName="p">{`res/raw`}</inlineCode>{` directory. Supported formats are `}<inlineCode parentName="p">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="p">{`wav`}</inlineCode>{`, or `}<inlineCode parentName="p">{`ogg`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "image-specifications"
    }}>{`Image specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Aspect ratios`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Image size range`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supported file types`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vertical product catalog: 1:1, 3:2, 5:4, 4:3 `}<br />{`Horizontal product catalog: 16:9, 2:1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`< 1 MB`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`PNG, JPG, WebP`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "text-guidelines"
    }}>{`Text guidelines`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Recommended max # of characters`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supports multiple lines?`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~35 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~35 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      