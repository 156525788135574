import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="ios" function="id" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public var id: String
`}</code></pre>
    <Variant platform="ios" function="auto-track" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public var autoTrack: Bool = true
`}</code></pre>
    <Variant platform="ios" function="view" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public var view: UIView? {
    fullscreenMessage?.webView
}
`}</code></pre>
    <Variant platform="ios" function="show" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift-3"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func show()
`}</code></pre>
    <Variant platform="ios" function="dismiss" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift-4"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func dismiss(suppressAutoTrack: Bool? = false)
`}</code></pre>
    <h6>{`Parameters`}</h6>
    <ul>
      <li parentName="ul"><em parentName="li">{`suppressAutoTrack`}</em>{` - if set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, the `}<inlineCode parentName="li">{`decisioning.propositionDismiss`}</inlineCode>{` Edge Event will not be sent regardless of the `}<inlineCode parentName="li">{`autoTrack`}</inlineCode>{` setting.`}</li>
    </ul>
    <Variant platform="ios" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift-5"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func track(_ interaction: String?, withEdgeEventType eventType: MessagingEdgeEventType)
`}</code></pre>
    <h6>{`Parameters`}</h6>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` - a custom `}<inlineCode parentName="li">{`String`}</inlineCode>{` value to be recorded in the interaction`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` - the `}<a parentName="li" {...{
          "href": "#enum-messagingedgeeventtype"
        }}><inlineCode parentName="a">{`MessagingEdgeEventType`}</inlineCode></a>{` to be used for the ensuing Edge Event`}</li>
    </ul>
    <Variant platform="ios" function="handle-javascript-message" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift-6"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func handleJavascriptMessage(_ name: String, withHandler handler: @escaping (Any?) -> Void)
`}</code></pre>
    <h5 {...{
      "id": "parameters"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`name`}</em>{` - the name of the message that should be handled by `}<inlineCode parentName="li">{`handler`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`handler`}</em>{` - the method or closure to be called with the body of the message created in the Message's JavaScript`}</li>
    </ul>
    <Variant platform="android" function="dismiss" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void dismiss(final boolean suppressAutoTrack)
`}</code></pre>
    <h5 {...{
      "id": "parameters-1"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`suppressAutoTrack`}</em>{` - if set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, the `}<inlineCode parentName="li">{`decisioning.propositionDismiss`}</inlineCode>{` Edge Event will not be sent regardless of the `}<inlineCode parentName="li">{`autoTrack`}</inlineCode>{` setting.`}</li>
    </ul>
    <Variant platform="android" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void track(final String interaction, final MessagingEdgeEventType eventType)
`}</code></pre>
    <h5 {...{
      "id": "parameters-2"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` - a custom `}<inlineCode parentName="li">{`String`}</inlineCode>{` value to be recorded in the interaction`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` - the `}<a parentName="li" {...{
          "href": "#enum-messagingedgeeventtype"
        }}><inlineCode parentName="a">{`MessagingEdgeEventType`}</inlineCode></a>{` to be used for the ensuing Edge Event`}</li>
    </ul>
    <Variant platform="android" function="show" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void show()
`}</code></pre>
    <Variant platform="android" function="view" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`WebView getWebView()
`}</code></pre>
    <Variant platform="android" function="handle-javascript-message" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void handleJavascriptMessage(final String name, final AdobeCallback<String> callback)
`}</code></pre>
    <h5 {...{
      "id": "parameters-3"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`name`}</em>{` - the name of the message that should be handled by the `}<inlineCode parentName="li">{`callback`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`callback`}</em>{` - a callback which will be called with the body of the message created in the Message's JavaScript`}</li>
    </ul>
    <Variant platform="android" function="auto-track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-5"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void setAutoTrack(boolean enabled)
`}</code></pre>
    <h5 {...{
      "id": "parameters-4"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`enabled`}</em>{` - if true, Experience Edge events will automatically be generated when this `}<inlineCode parentName="li">{`Message`}</inlineCode>{` is triggered, displayed, or dismissed.`}</li>
    </ul>
    <Variant platform="android" function="evaluate-javascript" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-6"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void evaluateJavascript(final String content)
`}</code></pre>
    <h5 {...{
      "id": "parameters-5"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`content`}</em>{` - a string containing the javascript code to be executed`}</li>
    </ul>
    <Variant platform="android" function="id" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-7"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`String getId()
`}</code></pre>
    <Variant platform="android" function="parent" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-8"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Object getParent()
`}</code></pre>
    <Variant platform="android" function="enum" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-9"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public enum MessagingEdgeEventType {
    IN_APP_DISMISS(0),
    IN_APP_INTERACT(1),
    IN_APP_TRIGGER(2),
    IN_APP_DISPLAY(3),
    PUSH_APPLICATION_OPENED(4),
    PUSH_CUSTOM_ACTION(5);

    final int value;

    MessagingEdgeEventType(final int value) {
        this.value = value;
    }

    public int getValue() {
        return value;
    }

    @Override
    public String toString() {
        switch (this) {
            case IN_APP_DISMISS:
                return MessagingConstants.EventDataKeys.Messaging.IAMDetailsDataKeys.EventType.DISMISS;
            case IN_APP_INTERACT:
                return MessagingConstants.EventDataKeys.Messaging.IAMDetailsDataKeys.EventType.INTERACT;
            case IN_APP_TRIGGER:
                return MessagingConstants.EventDataKeys.Messaging.IAMDetailsDataKeys.EventType.TRIGGER;
            case IN_APP_DISPLAY:
                return MessagingConstants.EventDataKeys.Messaging.IAMDetailsDataKeys.EventType.DISPLAY;
            case PUSH_APPLICATION_OPENED:
                return MessagingConstants.EventDataKeys.Messaging.PushNotificationDetailsDataKeys.EventType.OPENED;
            case PUSH_CUSTOM_ACTION:
                return MessagingConstants.EventDataKeys.Messaging.PushNotificationDetailsDataKeys.EventType.CUSTOM_ACTION;
            default:
                return super.toString();
        }
    }
`}</code></pre>
    <Variant platform="ios" function="enum" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift-7"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(AEPMessagingEdgeEventType)
public enum MessagingEdgeEventType: Int {
    case inappDismiss = 0
    case inappInteract = 1
    case inappTrigger = 2
    case inappDisplay = 3
    case pushApplicationOpened = 4
    case pushCustomAction = 5

    public func toString() -> String {
        switch self {
        case .inappDismiss:
            return MessagingConstants.XDM.IAM.EventType.DISMISS
        case .inappTrigger:
            return MessagingConstants.XDM.IAM.EventType.TRIGGER
        case .inappInteract:
            return MessagingConstants.XDM.IAM.EventType.INTERACT
        case .inappDisplay:
            return MessagingConstants.XDM.IAM.EventType.DISPLAY
        case .pushCustomAction:
            return MessagingConstants.XDM.Push.EventType.CUSTOM_ACTION
        case .pushApplicationOpened:
            return MessagingConstants.XDM.Push.EventType.APPLICATION_OPENED
        }
    }
}
`}</code></pre>
    <Variant platform="android" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`String value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IN_APP_DISMISS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDismiss`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IN_APP_INTERACT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionInteract`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IN_APP_TRIGGER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionTrigger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IN_APP_DISPLAY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PUSH_APPLICATION_OPENED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.applicationOpened`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PUSH_CUSTOM_ACTION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.customAction`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <Variant platform="ios" function="string-values" repeat="1" mdxType="Variant" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Case`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`String value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inappDismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDismiss`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inappInteract`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionInteract`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inappTrigger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionTrigger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inappDisplay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decisioning.propositionDisplay`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushApplicationOpened`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.applicationOpened`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushCustomAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pushTracking.customAction`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      