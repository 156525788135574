import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" api="get-propositions-for-surfaces" repeat="9" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void getPropositionsForSurfaces(@NonNull final List<Surface> surfaces, @NonNull final AdobeCallback<Map<Surface, List<Proposition>>> callback)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is a list of surfaces for which propositions are requested.`}</li>
      <li parentName="ul"><em parentName="li">{`callback`}</em>{` `}<inlineCode parentName="li">{`call`}</inlineCode>{` method is invoked with propositions map of type `}<inlineCode parentName="li">{`Map<Surface, List<Proposition>>`}</inlineCode>{`. If the callback is an instance of `}<a parentName="li" {...{
          "href": "../../../../home/base/mobile-core/api-reference.md#adobecallbackwitherror"
        }}>{`AdobeCallbackWithError`}</a>{`, and if the operation times out or an error occurs in retrieving propositions, the `}<inlineCode parentName="li">{`fail`}</inlineCode>{` method is invoked with the appropriate `}<a parentName="li" {...{
          "href": "../../../../home/base/mobile-core/api-reference.md#adobeerror"
        }}>{`AdobeError`}</a>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val surface1 = Surface("myActivity#button")
val surface2 = Surface("myActivityAttributes")
val surfaces = listOf(surface1, surface2)

Messaging.getPropositionsForSurfaces(surfaces) {
  it?.let { propositionsMap ->
    if (propositionsMap.isNotEmpty()) {
      // get the propositions for the given surfaces
      propositionsMap[surface1]?.let {
        // read surface1 propositions
      }
      propositionsMap[surface2]?.let {
        // read surface2 propositions
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final Surface surface1 = new Surface("myActivity#button");
final Surface surface2 = new Surface("myActivityAttributes");

final List<Surface> surfaces = new ArrayList<>();
surfaces.add(surface1);
surfaces.add(surface2);

Messaging.getPropositionsForSurfaces(surfaces, new AdobeCallbackWithError<Map<Surface, List<Proposition>>>() {
    @Override
    public void fail(final AdobeError adobeError) {
        // handle error
    }

    @Override
    public void call(Map<Surface, List<Proposition>> propositionsMap) {
        if (propositionsMap != null && !propositionsMap.isEmpty()) {
            // get the propositions for the given surfaces
            if (propositionsMap.contains(surface1)) {
                final List<Proposition> propositions1 = propositionsMap.get(surface1)
                // read surface1 propositions
            }
            if (propositionsMap.contains(surface2)) {
                final List<Proposition> proposition2 = propositionsMap.get(surface2)
                // read surface2 propositions
            }
        }
    }
});
`}</code></pre>
    <Variant platform="ios" api="get-propositions-for-surfaces" repeat="12" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func getPropositionsForSurfaces(_ surfacePaths: [Surface], _ completion: @escaping ([Surface: [Proposition]]?, Error?) -> Void)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is an array of surfaces for which propositions are requested.`}</li>
      <li parentName="ul"><em parentName="li">{`completion`}</em>{` is invoked with propositions dictionary of type `}<inlineCode parentName="li">{`[Surface: [Proposition]]`}</inlineCode>{`. An `}<inlineCode parentName="li">{`Error`}</inlineCode>{` is returned if SDK fails to retrieve the propositions.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let surface1 = Surface(path: "myView#button")
let surface2 = Surface(path: "myViewAttributes")

Messaging.getPropositionsForSurfaces([surface1, surface2]) { propositionsDict, error in
    guard error == nil else {
        // handle error
        return
    }

    guard let propositionsDict = propositionsDict else {
        // bail early if no propositions
        return
    }
    
    // get the propositions for the given surfaces
    if let propositions1 = propositionsDict[surface1] {
        // read surface1 propositions
    }

    if let propositions2 = propositionsDict[surface2] {
        // read surface2 propositions
    }
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`+ (void) getPropositionsForSurfaces: (NSArray<AEPSurface*>* _Nonnull) surfaces 
              completion: (void (^ _Nonnull)(NSDictionary<AEPSurface*, NSArray<AEPProposition*>*>* _Nullable propositionsDict, NSError* _Nullable error)) completion;
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is an array of surfaces for which propositions are requested.`}</li>
      <li parentName="ul"><em parentName="li">{`completion`}</em>{` is invoked with propositions dictionary of type `}<inlineCode parentName="li">{`NSDictionary<AEPSurface*, NSArray<AEPProposition*>*>`}</inlineCode>{`. An `}<inlineCode parentName="li">{`NSError`}</inlineCode>{` is returned if SDK fails to retrieve the propositions.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`AEPSurface* surface1 = [[AEPSurface alloc] initWithPath: @"myView#button"];
AEPSurface* surface2 = [[AEPSurface alloc] initWithPath: @"myView#button"];

[AEPMobileMessaging getPropositionsForSurfaces: @[surface1, surface2] 
                        completion: ^(NSDictionary<AEPDecisionScope*, NSArray<AEPProposition*>*>* propositionsDict, NSError* error) {
  if (error != nil) {
    // handle error   
    return;
  }

  NSArray<AEPProposition*>* proposition1 = propositionsDict[surface1];
  // read surface1 propositions

  NSArray<AEPProposition*>* proposition2 = propositionsDict[surface2];
  // read surface2 propositions
}];
`}</code></pre>
    <Variant platform="android" api="update-propositions-for-surfaces" repeat="9" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void updatePropositionsForSurfaces(@NonNull final List<Surface> surfaces)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is a list of surfaces for which propositions need updating.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val surface1 = Surface("myActivity#button")
val surface2 = Surface("myActivityAttributes")
val surfaces = listOf(surface1, surface2)

Messaging.updatePropositionsForSurfaces(surfaces)
`}</code></pre>
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final Surface surface1 = new Surface("myActivity#button");
final Surface surface2 = new Surface("myActivityAttributes");

final List<Surface> surfaces = new ArrayList<>();
surfaces.add(surface1);
surfaces.add(surface2);

Messaging.updatePropositionsForSurfaces(surfaces)
`}</code></pre>
    <Variant platform="ios" api="update-propositions-for-surfaces" repeat="12" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func updatePropositionsForSurfaces(_ surfaces: [Surface])
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is an array of surfaces for which propositions need updating.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let surface1 = Surface(path: "myView#button")
let surface2 = Surface(path: "myViewAttributes")

Messaging.updatePropositionsForSurfaces([surface1, surface2])
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`+ (void) updatePropositionsForSurfaces: (NSArray<AEPSurface*>* _Nonnull) surfaces;
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`surfaces`}</em>{` is an array of surfaces for which propositions need updating.`}</li>
    </ul>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`AEPSurface* surface1 = [[AEPSurface alloc] initWithPath: @"myView#button"];
AEPSurface* surface2 = [[AEPSurface alloc] initWithPath: @"myView#button"];

[AEPMobileMessaging updatePropositionsForSurfaces: @[surface1, surface2]]; 
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      