import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="config" repeat="4" mdxType="Variant" />
    <p>{`If using Gradle, remove the v4 Mobile SDK dependency:`}</p>
    <h4 {...{
      "id": "gradle"
    }}>{`Gradle`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dependencies {
  implementation 'com.adobe.mobile:adobeMobileLibrary:4.18.2'
  ...
}
`}</code></pre>
    <p>{`Alternatively, if the v4 Mobile SDK library is linked as a jar, search for `}<inlineCode parentName="p">{`adobeMobileLibrary`}</inlineCode>{` in your project and remove the jar file.`}</p>
    <Variant platform="ios" task="config" repeat="3" mdxType="Variant" />
    <p>{`If using Cocoapods, remove the v4 Mobile SDK dependency from the Podfile:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`target 'YourTarget' do
    pod 'AdobeMobileSDK'
    ...
end
`}</code></pre>
    <p>{`Alternatively, if the v4 Mobile SDK library is linked in Xcode, select the application target and go to `}<inlineCode parentName="p">{`Build Phases`}</inlineCode>{`, then `}<inlineCode parentName="p">{`Link Binary With Libraries`}</inlineCode>{` and remove `}<inlineCode parentName="p">{`AdobeMobileLibrary.a`}</inlineCode>{`.`}</p>
    <Variant platform="android" task="aep-install" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Analytics;
import com.adobe.marketing.mobile.Identity;

@Override
public void onCreate(Bundle savedInstanceState) {
  super.onCreate(savedInstanceState);
  setContentView(R.layout.main);

  MobileCore.setApplication(getApplication());

  MobileCore.registerExtensions(Arrays.asList(
     Analytics.EXTENSION,
     Identity.EXTENSION
    ), value -> {
   // add your Environment file ID from Environments tab in Data Collection tags.
   MobileCore.configureWithAppID("your-environment-file-id");
 });
}
`}</code></pre>
    <Variant platform="ios" task="aep-install" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPCore
import AEPIdentity
import AEPAnalytics

func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplicationLaunchOptionsKey: Any]?) -> Bool {
  MobileCore.registerExtensions([Analytics.self, Identity.self], {
      // Use the environment file id assigned to this application in Data Collection UI
      MobileCore.configureWith(appId: "your-environment-file-id")
  })
  return true
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.h
@import AEPCore;
@import AEPIdentity;
@import AEPAnalytics;

// AppDelegate.m
- (BOOL) application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  [AEPMobileCore registerExtensions:@[AEPMobileAnalytics.class, AEPMobileIdentity.class] completion:^{
      // Use the environment file id assigned to this application in Data Collection UI
      [AEPMobileCore configureWithAppId: @"your-environment-file-id"];
  }];
  return YES;
}
`}</code></pre>
    <Variant platform="android" task="api-changes-v4" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p>{`The Adobe Mobile Library (v4) syntax and usage examples for these API are:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// syntax
public static void trackState(final String state, final Map<String, Object> contextData)

// usage
Analytics.trackState("MainPage", new HashMap<String, Object>() {{
  put("firstVisit", true);
}});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// syntax
public static void trackAction(final String action, final Map<String, Object> contextData)

// usage
Analytics.trackAction("linkClicked", new HashMap<String, Object>() {{
  put("url", "https://www.adobe.com");
}});
`}</code></pre>
    <Variant platform="ios" task="api-changes-v4" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p>{`The Adobe Mobile Library (v4) syntax and usage examples for these API are:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// syntax
+ (void) trackState:(NSString *)state data:(NSDictionary *)data;

// usage
[ADBMobile trackState:@"MainPage" data:@{@"firstVisit":@true}];
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// syntax
+ (void) trackAction:(NSString *)action data:(NSDictionary *)data;

// usage
[ADBMobile trackAction:@"linkClicked" data:@{@"url":@"https://www.adobe.com"}];
`}</code></pre>
    <Variant platform="android" task="api-changes-aep" repeat="4" mdxType="Variant" />
    <p>{`The Mobile SDKs have moved the `}<inlineCode parentName="p">{`trackAction`}</inlineCode>{` and `}<inlineCode parentName="p">{`trackState`}</inlineCode>{` APIs to the MobileCore extension. In addition, the context data Map has been changed from `}<inlineCode parentName="p">{`<String, Object>`}</inlineCode>{` to `}<inlineCode parentName="p">{`<String, String>`}</inlineCode>{`. The syntax is:`}</p>
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// syntax
public static void trackState(final String state, final Map<String, String> contextData)

// usage
MobileCore.trackState("MainPage", new HashMap<String, String>() {{
  put("firstVisit", "true");
}});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// syntax
public static void trackAction(final String action, final Map<String, String> contextData)

// usage
MobileCore.trackAction("linkClicked", new HashMap<String, String>() {{
  put("url", "https://www.adobe.com");
}});
`}</code></pre>
    <Variant platform="ios" task="api-changes-aep" repeat="8" mdxType="Variant" />
    <p>{`The Mobile SDKs have moved the `}<inlineCode parentName="p">{`trackAction`}</inlineCode>{` and `}<inlineCode parentName="p">{`trackState`}</inlineCode>{` APIs to the MobileCore extension. The syntax is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{` @objc(trackAction:data:)
 static func track(action: String?, data: [String: Any]?)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{` @objc(trackState:data:)
 static func track(state: String?, data: [String: Any]?)
`}</code></pre>
    <p>{`The usage examples are:`}</p>
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.track(state: "MainPage", data: ["firstVisit": "true"])
MobileCore.track(action: "linkClicked", data: ["url": "https://www.adobe.com"])
`}</code></pre>
    <h4 {...{
      "id": "objective-c-2"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore trackState:@"MainPage" data:@{@"firstVisit":@"true"}];
[AEPMobileCore trackAction:@"linkClicked" data:@{@"url":@"https://www.adobe.com"}];
`}</code></pre>
    <Variant platform="android" task="privacy-changes-aep" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <p>{`The usage example for the `}<inlineCode parentName="p">{`setPrivacyStatus`}</inlineCode>{` API is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.setPrivacyStatus(MobilePrivacyStatus.OPT_IN);
MobileCore.setPrivacyStatus(MobilePrivacyStatus.OPT_OUT);
MobileCore.setPrivacyStatus(MobilePrivacyStatus.UNKNOWN);
`}</code></pre>
    <p>{`The usage example for the `}<inlineCode parentName="p">{`getPrivacyStatus`}</inlineCode>{` API is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.getPrivacyStatus(new AdobeCallback<MobilePrivacyStatus>() {
    @Override
    public void call(MobilePrivacyStatus status) {
        // handle current privacy status
    }
});
`}</code></pre>
    <Variant platform="ios" task="privacy-changes-aep" repeat="5" mdxType="Variant" />
    <p>{`The usage example for `}<inlineCode parentName="p">{`getPrivacyStatus`}</inlineCode>{` is:`}</p>
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.getPrivacyStatus(completion: ({ status in
  // handle current privacy status
   switch status {
     case PrivacyStatus.optedIn: print("Privacy Status: Opt-In")
     case PrivacyStatus.optedOut: print("Privacy Status: Opt-Out")
     case PrivacyStatus.unknown: print("Privacy Status: Unknown")
     default: break
   }
})
`}</code></pre>
    <h4 {...{
      "id": "objective-c-3"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore getPrivacyStatus:^(AEPPrivacyStatus status) {
    switch (status) {
    case AEPPrivacyStatusOptedIn: NSLog(@"Privacy Status: Opt-In");
    case AEPPrivacyStatusOptedOut: NSLog(@"Privacy Status: Opt-Out");
    case AEPPrivacyStatusUnknown: NSLog(@"Privacy Status: Unknown");
    default: break;
  }
}];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      