import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineAlert = makeShortcode("InlineAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following sample shows how to attach data to all outgoing `}<inlineCode parentName="p">{`TrackAction`}</inlineCode>{` Analytics network requests. To create this type of rule, select your property in the Data Collection UI and complete the following steps.`}</p>
    <h4 {...{
      "id": "create-a-rule"
    }}>{`Create a rule`}</h4>
    <ol>
      <li parentName="ol">{`On the `}<strong parentName="li">{`Rules`}</strong>{` tab, select `}<strong parentName="li">{`Create New Rule`}</strong>{`.`}</li>
    </ol>
    <InlineAlert variant="info" slots="text" mdxType="InlineAlert" />
    <p>{`If you do not have existing rules for this property, the `}<strong parentName="p">{`Create New Rule`}</strong>{` button will be in the middle of the screen. If your property has rules, the button will be in the top right of the screen.`}</p>
    <h4 {...{
      "id": "select-an-event"
    }}>{`Select an event`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Give your rule an easily recognizable name in your list of rules.`}</p>
        <p parentName="li">{`In this example, the rule is named "Attach Places Data to Analytics Track Action Events".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the `}<strong parentName="p">{`Events`}</strong>{` section, select `}<strong parentName="p">{`Add`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="p">{`Mobile Core`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`From the `}<strong parentName="p">{`Event Type`}</strong>{` dropdown list, select `}<strong parentName="p">{`Track Action`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<strong parentName="p">{`Keep Changes`}</strong>{`.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.5625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/fb8ef8c674819ace058337e77c877680/5530d/set-event.webp 320w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/0c8fb/set-event.webp 640w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/94b1e/set-event.webp 1280w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/0b34d/set-event.webp 1920w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/ca1bd/set-event.webp 2132w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/fb8ef8c674819ace058337e77c877680/dd4a7/set-event.png 320w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/0f09e/set-event.png 640w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/bbbf7/set-event.png 1280w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/ac7a9/set-event.png 1920w", "/client-sdks/static/fb8ef8c674819ace058337e77c877680/d0fcb/set-event.png 2132w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/fb8ef8c674819ace058337e77c877680/bbbf7/set-event.png",
            "alt": "set event",
            "title": "set event",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "define-the-action"
    }}>{`Define the action`}</h4>
    <ol>
      <li parentName="ol">{`Under the `}<strong parentName="li">{`Actions`}</strong>{` section, select `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Extension`}</strong>{` dropdown list, select `}<strong parentName="li">{`Mobile Core`}</strong>{`.`}</li>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Action Type`}</strong>{` dropdown list, select `}<strong parentName="li">{`Attach Data`}</strong>{`.`}</li>
      <li parentName="ol">{`On the right pane, in the `}<strong parentName="li">{`JSON Payload`}</strong>{` field, type the data that will be added to this event.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Keep Changes`}</strong>{`.`}</li>
    </ol>
    <p>{`On the right pane, you can add a freeform JSON payload that adds data to an SDK event before an extension that is listening for this event can hear the event. In this example, some context data is added to this event before the Adobe Analytics extension processes it. The added context data will now be on the outgoing Adobe Analytics hit.`}</p>
    <p>{`In the following example, `}<inlineCode parentName="p">{`launches`}</inlineCode>{` and `}<inlineCode parentName="p">{`anAddedKey`}</inlineCode>{` keys are added to the `}<inlineCode parentName="p">{`contextdata`}</inlineCode>{` of the Analytics event. Values for the new keys can either be hardcoded in the rule, or dynamically determined by the SDK when this event processes by using data elements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.56250000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/5530d/set-action.webp 320w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/0c8fb/set-action.webp 640w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/94b1e/set-action.webp 1280w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/0b34d/set-action.webp 1920w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/8abe9/set-action.webp 2064w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/dd4a7/set-action.png 320w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/0f09e/set-action.png 640w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/bbbf7/set-action.png 1280w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/ac7a9/set-action.png 1920w", "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/a2d0e/set-action.png 2064w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/47f4731192f637c53fefe4ea3a401dca/bbbf7/set-action.png",
            "alt": "set action",
            "title": "set action",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h4 {...{
      "id": "save-the-rule-and-rebuild-your-property"
    }}>{`Save the rule and rebuild your property`}</h4>
    <p>{`After you complete your configuration, verify that your rule looks like the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.0625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/5530d/rule-complete.webp 320w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/0c8fb/rule-complete.webp 640w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/94b1e/rule-complete.webp 1280w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/0b34d/rule-complete.webp 1920w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/8abe9/rule-complete.webp 2064w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/dd4a7/rule-complete.png 320w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/0f09e/rule-complete.png 640w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/bbbf7/rule-complete.png 1280w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/ac7a9/rule-complete.png 1920w", "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/a2d0e/rule-complete.png 2064w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/client-sdks/static/68d9d123ddfb0adf2a74445f8672f170/bbbf7/rule-complete.png",
            "alt": "rule complete",
            "title": "rule complete",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save`}</strong></li>
      <li parentName="ol">{`Rebuild your mobile property and deploy it to the correct Environment.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      