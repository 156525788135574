import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="fcm" template="input-box" repeat="15" mdxType="Variant" />
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`The properties below define the payload sent to FCM:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Required`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Key`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payload Version`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Version of the payload assigned by the ACC authoring UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Template Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Informs the reader which properties may exist in the template object.`}<br />{`Input-box template uses a value of "input".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in the notification's title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_title`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text shown in message body when notification is collapsed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Expanded Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_body_ex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Body of the message when the message is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Body`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text color for `}<inlineCode parentName="td">{`adb_body`}</inlineCode>{`, `}<inlineCode parentName="td">{`adb_body_ex`}</inlineCode>{`.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sound`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sound played when notification is delivered.`}<br />{`Value should be the name of the sound file without its extension.`}<br />{`Sound file should be located in the `}<inlineCode parentName="td">{`assets/raw`}</inlineCode>{` directory of your app.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI of an image to be shown when notification is expanded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link URI`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI to be handled when user clicks the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Link Type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_a_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Type of link represented in `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` - one of "WEBURL", "DEEPLINK", "DISMISS", or "OPENAPP".`}<br />{`Required if `}<inlineCode parentName="td">{`adb_uri`}</inlineCode>{` is specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a small icon to use in the notification. `}<br /><strong parentName="td">{`Note`}</strong>{` - The value referenced by this key is not used if a valid `}<inlineCode parentName="td">{`adb_small_icon`}</inlineCode>{` key value pair is present in the payload.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_small_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a small icon to use in the notification. The app's drawable resources are checked for an image file with the provided name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Small Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for the notification's small icon.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Large Icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_large_icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI or name for a large icon to use in the notification. If the URI is valid, the remote image is downloaded and cached on the device to be used as the large icon. If a name is provided, the app's drawable resources are checked for an image file with the same name. If no valid URI or name is provided then no large icon is set on the notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Channel ID`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_channel_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The `}<a parentName="td" {...{
              "href": "https://developer.android.com/guide/topics/ui/notifiers/notifications#ManageChannels"
            }}>{`notification's channel id`}</a>{` (new in Android O). The app must create a channel with this channel ID before any notification with this channel ID is received. If you don't send this channel ID in the request, or if the channel ID provided has not yet been created by the app, FCM uses the channel ID specified in the app manifest.`}<br />{`If not provided in payload, SDK uses a "default" channel ID of value "General Notifications".`}<br />{`If < API 26 (Android O), this value is ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Badge Count`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value to be used on app's badge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Priority`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_n_priority`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Designates the notification priority for Android - `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/android/app/NotificationChannel#setImportance(int)"
            }}>{`importance`}</a>{` for API >= 26 (Android 8.0) or `}<a parentName="td" {...{
              "href": "https://developer.android.com/reference/androidx/core/app/NotificationCompat.Builder#setPriority(int)"
            }}>{`priority`}</a>{` for API < 26.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Identifier used to replace existing notifications in the notification drawer. If not specified, each request creates a new notification. If specified and a notification with the same tag is already being shown, the new notification replaces the existing one in the notification drawer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sticky`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_sticky`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean value represented as a string "true" or "false".`}<br />{`When set to false or unset, the notification is automatically dismissed when the user clicks it in the panel. When set to true, the notification persists even when the user clicks it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ticker`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_ticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the "ticker" text, which is sent to accessibility services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color - Background`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_clr_bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Color for notification's background.`}<br />{`Represented as six character hex, e.g. `}<inlineCode parentName="td">{`00FF00`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Input receiver name`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_input_receiver`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required, name of the `}<inlineCode parentName="td">{`Intent`}</inlineCode>{` action to use when broadcasting the user input.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Input text`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_input_txt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Placeholder text for the text input field.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Feedback text`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_feedback_txt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Once feedback has been submitted, use this text as the notification's body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Feedback image`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`⛔️`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`adb_feedback_img`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Once feedback has been submitted, use this as the notification's image.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <img {...{
      "src": "/client-sdks/assets/728e82bf7465bfe86185b5ba4be15fa9/input_box.gif",
      "width": "50%",
      "height": "50%"
    }}></img>
    <p>{`Below is a sample of what a payload might look like for an input box notification:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message": {
    "token": "FCM_TOKEN",
    "android": {
      "collapse_key": "new message"
    },
    "data": {    
      "adb_version": "1",
      "adb_title": "Check out the new line of shoes!",
      "adb_body": "Do you have any favorites?",
      "adb_sound": "sneakerSqueaker",
      "adb_image": "https://sneakerland.com/products/shoe1/shoe1.png",
      "adb_uri": "https://sneakerland.com/newReleases",
      "adb_icon": "ic_shoe",
      "adb_a_type": "WEBURL",
      "adb_template_type": "input",
      "adb_body_ex": "What brands are you interested in?",
      "adb_clr_body": "00EE00",
      "adb_clr_title": "AABBCC",
      "adb_clr_icon": "123456",
      "adb_clr_bg": "000000",
      "adb_input_txt": "Enter your favorite brands",
      "adb_feedback_txt": "Thanks for helping us provide you with an experience more catered to your tastes!",
      "adb_feedback_img": "https://sneakerland.com/img/thanks.png",
      "adb_input_receiver": "developer intent action name"
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "usage-recommendations"
    }}>{`Usage recommendations`}</h2>
    <p>{`The tables below contain guidelines for your push notification content. `}<strong parentName="p">{`These recommendations help your text and images display reliably across multiple devices.`}</strong>{` These values are guidelines only - you should still test a notification prior to sending it.`}</p>
    <h3 {...{
      "id": "audio-specifications"
    }}>{`Audio specifications`}</h3>
    <p>{`Custom sound files must be placed within the app's bundle in the `}<inlineCode parentName="p">{`res/raw`}</inlineCode>{` directory. Supported formats are `}<inlineCode parentName="p">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="p">{`wav`}</inlineCode>{`, or `}<inlineCode parentName="p">{`ogg`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "image-specifications"
    }}>{`Image specifications`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Aspect ratios`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Image size range`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supported file types`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1:1, 3:2, 5:4, 4:3, 2:1, 16:9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`< 1 MB`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`PNG, JPG, WebP`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "text-guidelines"
    }}>{`Text guidelines`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Recommended max # of characters`}</strong></th>
          <th parentName="tr" {...{
            "align": "right"
          }}><strong parentName="th">{`Supports multiple lines?`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Title`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~35 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`~110 (depends on screen size and device font setting)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      