import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="ios" function="dismiss" repeat="3" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func dismiss(suppressAutoTrack: Bool? = false)
`}</code></pre>
    <h6>{`Parameters`}</h6>
    <ul>
      <li parentName="ul"><em parentName="li">{`suppressAutoTrack`}</em>{`: If set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`, the `}<inlineCode parentName="li">{`decisioning.propositionDismiss`}</inlineCode>{` Edge Event will not be sent regardless of the `}<inlineCode parentName="li">{`autoTrack`}</inlineCode>{` setting.`}</li>
    </ul>
    <Variant platform="ios" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func track(_ interaction: String?, withEdgeEventType eventType: MessagingEdgeEventType)
`}</code></pre>
    <h6>{`Parameters`}</h6>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{`: A custom `}<inlineCode parentName="li">{`String`}</inlineCode>{` value to be recorded in the interaction`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{`: The `}<a parentName="li" {...{
          "href": "#enum-messagingedgeeventtype"
        }}><inlineCode parentName="a">{`MessagingEdgeEventType`}</inlineCode></a>{` to be used for the ensuing Edge Event`}</li>
    </ul>
    <Variant platform="ios" function="handle-javascript-message" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func handleJavascriptMessage(_ name: String, withHandler handler: @escaping (Any?) -> Void)
`}</code></pre>
    <h5 {...{
      "id": "parameters"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`name`}</em>{`: The name of the message that should be handled by `}<inlineCode parentName="li">{`handler`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`handler`}</em>{`: The method or closure to be called with the body of the message created in the Message's JavaScript`}</li>
    </ul>
    <Variant platform="android" function="dismiss" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void dismiss()
`}</code></pre>
    <Variant platform="android" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void track(final String interaction, final MessagingEdgeEventType eventType)
`}</code></pre>
    <h5 {...{
      "id": "parameters-1"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{`: A custom `}<inlineCode parentName="li">{`String`}</inlineCode>{` value to be recorded in the interaction`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{`: The `}<a parentName="li" {...{
          "href": "../messaging-edge-event-type.md"
        }}><inlineCode parentName="a">{`MessagingEdgeEventType`}</inlineCode></a>{` to be used for the ensuing Edge Event`}</li>
    </ul>
    <Variant platform="android" function="handle-javascript-message" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void handleJavascriptMessage(final String name, final AdobeCallback<String> callback)
`}</code></pre>
    <h5 {...{
      "id": "parameters-2"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`name`}</em>{`: The name of the message that should be handled by the `}<inlineCode parentName="li">{`callback`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`callback`}</em>{`: A callback which will be called with the body of the message created in the Message's JavaScript`}</li>
    </ul>
    <Variant platform="android" function="set-auto-track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void setAutoTrack(boolean enabled)
`}</code></pre>
    <h5 {...{
      "id": "parameters-3"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`enabled`}</em>{`: If true, Experience Edge events will automatically be generated when this `}<inlineCode parentName="li">{`Message`}</inlineCode>{` is triggered, displayed, or dismissed.`}</li>
    </ul>
    <Variant platform="android" function="get-auto-track" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`boolean getAutoTrack()
`}</code></pre>
    <Variant platform="android" function="evaluate-javascript" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-5"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`void evaluateJavascript(final String content)
`}</code></pre>
    <h5 {...{
      "id": "parameters-4"
    }}>{`Parameters`}</h5>
    <ul>
      <li parentName="ul"><em parentName="li">{`content`}</em>{`: A string containing the JavaScript code to be executed`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      