import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Currently, `}<inlineCode parentName="p">{`CLBeacon`}</inlineCode>{` is only available on iOS. The sample code contains the necessary checks to ensure OS compatibility.`}</p>
    <p>{`This example uses `}<inlineCode parentName="p">{`static`}</inlineCode>{` constant strings that were provided in the `}<inlineCode parentName="p">{`trackBeacon`}</inlineCode>{` code sample above.`}</p>
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`#if os(iOS)
class func clearCurrentBeacon() {
    UserProfile.removeUserAttributes(attributeNames: [BEACON_MAJOR, BEACON_MINOR, BEACON_UUID, BEACON_PROXIMITY])
}
#endif
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`#if TARGET_OS_IOS
+ (void) clearCurrentBeacon {
    [AEPMobileUserProfile removeUserAttributesWithAttributeNames: @[BEACON_MAJOR, BEACON_MINOR, BEACON_UUID, BEACON_PROXIMITY]];
}
#endif
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      