import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="ios" function="generate-interaction-xdm" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func generateInteractionXdm(_ interaction: String? = nil, withEdgeEventType eventType: MessagingEdgeEventType, forTokens tokens: [String]? = nil) -> [String: Any]?
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` is a custom string value describing the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`tokens`}</em>{` is an array containing the decision item tokens for recording interaction.`}</li>
    </ul>
    <Variant platform="ios" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func track(_ interaction: String? = nil, withEdgeEventType eventType: MessagingEdgeEventType, forTokens tokens: [String]? = nil)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` is a custom string value describing the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`tokens`}</em>{` is an array containing the decision item tokens for recording interaction.`}</li>
    </ul>
    <Variant platform="android" function="get-content-card-schema-data" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public ContentCardSchemaData getContentCardSchemaData()
`}</code></pre>
    <Variant platform="android" function="get-inapp-schema-data" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public InAppSchemaData getInAppSchemaData()
`}</code></pre>
    <Variant platform="android" function="get-item-id" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public String getItemId()
`}</code></pre>
    <Variant platform="android" function="get-item-data" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Map<String, Object> getItemData()
`}</code></pre>
    <Variant platform="android" function="get-schema" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public SchemaType getSchema()
`}</code></pre>
    <Variant platform="android" function="get-html-content" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-5"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public String getHtmlContent()
`}</code></pre>
    <Variant platform="android" function="get-json-content-map" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-6"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Map<String, Object> getJsonContentMap()
`}</code></pre>
    <Variant platform="android" function="get-json-content-array-list" repeat="3" mdxType="Variant" />
    <h4 {...{
      "id": "java-7"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public List<Map<String, Object>> getJsonContentArrayList()
`}</code></pre>
    <Variant platform="android" function="generate-interaction-xdm" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-8"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Map<String, Object> generateInteractionXdm(@NonNull final MessagingEdgeEventType eventType)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
    </ul>
    <Variant platform="android" function="generate-interaction-xdm-with-tokens" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-9"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public Map<String, Object> generateInteractionXdm(final String interaction, @NonNull final MessagingEdgeEventType eventType, final List<String> tokens)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` is a custom string value describing the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`tokens`}</em>{` is a list containing the decision item tokens for recording interaction.`}</li>
    </ul>
    <Variant platform="android" function="track" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-10"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void track(@NonNull final MessagingEdgeEventType eventType)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
    </ul>
    <Variant platform="android" function="track-with-tokens" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-11"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void track(final String interaction, @NonNull final MessagingEdgeEventType eventType, final List<String> tokens)
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`interaction`}</em>{` is a custom string value describing the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`eventType`}</em>{` is an enum specifying event type for the interaction.`}</li>
      <li parentName="ul"><em parentName="li">{`tokens`}</em>{` is a list containing the decision item tokens for recording interaction.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      